import {ImageURISource} from "react-native";
import React from "react";
import FFP from "./FFP";
import {ProtectionClasses} from "../forms/EquipmentForm";

class FFP2 extends FFP {
  readonly name: string = 'półmaska filtrująca klasy FFP2';
  readonly image: ImageURISource = require('../assets/equipment/FFP2.jpg');
  readonly protectionClass: ProtectionClasses = ProtectionClasses.P2;

  readonly code: string = 'FFP2';
  readonly index: string = 'FFP2';
  readonly oil: string = '6';
  readonly aerosol: string = '6';
  readonly leakage: string = '11/8';
}

export default new FFP2();
