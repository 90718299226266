import {createStackNavigator} from "@react-navigation/stack";
import * as React from "react";
import FormScreen from "./FormScreen";
import ResultsScreen from "./ResultsScreen";
import {useTranslation} from "react-i18next";
import {ScreenOptions} from "../index";

export type FormParamList = {
  Form: undefined;
  Results: undefined;
  EquipmentDetail: {
    id: number;
  },
};

// @ts-ignore
let FormStack;
let options;

FormStack = createStackNavigator<FormParamList>();
options = ScreenOptions;

export default function FormNavigator() {
  const { t } = useTranslation();

  // @ts-ignore
  return (
    // @ts-ignore
    <FormStack.Navigator
      screenOptions={ScreenOptions}
    >
      {/*@ts-ignore*/}
      <FormStack.Screen
        name="Form"
        component={FormScreen}
        options={{
          title: t('FormScreen:title'),
          headerTitle: t('FormScreen:title'),
        }}
      />

      {/*// @ts-ignore*/}
      <FormStack.Screen
        name="Results"
        component={ResultsScreen}
        options={{
          title: t('ResultsScreen:title'),
          headerTitle: t('ResultsScreen:title'),
        }}
      />
    {/* @ts-ignore*/}
    </FormStack.Navigator>
  );
}
