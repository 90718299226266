import {IEquipmentType} from "./index";
import {ImageURISource} from "react-native";
import {Box, HStack, Text, VStack} from "native-base";
import React from "react";
import {TFunction} from "i18next";
import {ProtectionClasses} from "../forms/EquipmentForm";

export default abstract class P implements IEquipmentType {
  abstract readonly name: string;
  abstract readonly image: ImageURISource;
  abstract readonly protectionClass: ProtectionClasses;

  abstract readonly code: string;
  abstract readonly index: string;
  abstract readonly oil: string;
  abstract readonly aerosol: string;

  readonly videos = [
    {uri: 'https://ciop-stats.devmouse.pl/movie/P.mov'},
    {uri: 'https://ciop-stats.devmouse.pl/movie/SealCheck.mov'},
  ];

  description = (t: TFunction): React.ReactNode => <VStack space={2}>
    <Text>{t('p_p1')}</Text>
    <Text>{t('p_p2')}</Text>
    <Text>{t('p_p3')}</Text>
    <Text>{t('p_p4')}</Text>
    <Text>{t('p_p5')}</Text>
    <Text>{t('p_p6')}</Text>

    <VStack space={1}>
      <Text textAlign='center' fontWeight='semibold'>{this.code}</Text>
      <HStack alignItems='center'>
        <Text flex={1}>{t('p_p7')}</Text>
        <Text fontWeight='semibold'>{this.oil}%</Text>
      </HStack>
      <HStack alignItems='center'>
        <Text flex={1}>{t('p_p8')}</Text>
        <Text fontWeight='semibold'>{this.aerosol}%</Text>
      </HStack>
    </VStack>
  </VStack>;

  warnings = (t: TFunction): React.ReactNode => <Box>
    <Text>{t('p_warn1')}</Text>
    <Text>{t('p_warn2')}</Text>
    <Text>{t('p_warn3')}</Text>
    <Text>{t('p_warn4')}</Text>
  </Box>;

  labeling = (t: TFunction): React.ReactNode => <Box>
    <Text>{t('p_label1')}</Text>
    <Text>{t('p_label2')}</Text>
    <Text>{t('p_label3')}</Text>
    <Text>{t('p_label4')}</Text>
    <Text>{t('p_label5')}</Text>
    <Text>{t('p_label6')}</Text>
    <Text>{t('p_label7')}</Text>

    <Text marginTop={2}>{t('p_label8')}</Text>
    <Text>{t('p_label9')}</Text>
    <Text>{t('p_label10')}</Text>
    <Text>{t('p_label11')}</Text>
    <Text>{t('p_label12')}</Text>
    <Text>{t('p_label13')}</Text>
    <Text>{t('p_label14')}</Text>
    <Text>{t('p_label15')}</Text>
    <Text>{t('p_label16')}</Text>
    <Text>{t('p_label17')}</Text>
    <Text>{t('p_label18')}</Text>

    <Text marginTop={2}>{t('p_label19')}</Text>
    <Text>{t('p_label20')}</Text>
  </Box>;
}
