import {IEquipmentType} from "./index";
import {ImageURISource} from "react-native";
import {Box, HStack, Text, VStack} from "native-base";
import React from "react";
import {TFunction} from "i18next";
import {ProtectionClasses} from "../forms/EquipmentForm";

export default abstract class M implements IEquipmentType {
  abstract readonly name: string;
  abstract readonly image: ImageURISource;
  abstract readonly protectionClass: ProtectionClasses;

  abstract readonly code: string;
  abstract readonly index: string;
  abstract readonly oil: string;
  abstract readonly aerosol: string;

  readonly videos = [
    {uri: 'https://ciop-stats.devmouse.pl/movie/M.mov'},
  ];

  description = (t: TFunction): React.ReactNode => <VStack space={2}>
    <Text>{t('m_p1')}</Text>
    <Text>{t('m_p2')}</Text>
    <Text>{t('m_p3')}</Text>
    <Text>{t('m_p4')}</Text>
    <Text>{t('m_p5')}</Text>
    <Text>{t('m_p6')}</Text>
    <Text>{t('m_p7')}</Text>
    <Text>{t('m_p8')}</Text>
    <Text>{t('m_p9')}</Text>
    <Text>{t('m_p10')}</Text>

    <VStack space={1}>
      <Text textAlign='center' fontWeight='semibold'>{this.code}</Text>
      <HStack alignItems='center'>
        <Text flex={1}>{t('m_p11')}</Text>
        <Text fontWeight='semibold'>{this.oil}%</Text>
      </HStack>
      <HStack alignItems='center'>
        <Text flex={1}>{t('m_p12')}</Text>
        <Text fontWeight='semibold'>{this.aerosol}%</Text>
      </HStack>
    </VStack>
  </VStack>;

  warnings = (t: TFunction): React.ReactNode => <Box>
    <Text>{t('m_warn1')}</Text>
    <Text>{t('m_warn2')}</Text>
    <Text>{t('m_warn3')}</Text>
    <Text>{t('m_warn4')}</Text>
  </Box>;

  labeling = (t: TFunction): React.ReactNode => <Box>
    <Text>{t('m_label1')}</Text>
    <Text>{t('m_label2')}</Text>
    <Text>{t('m_label3')}</Text>
    <Text>{t('m_label4')}</Text>
    <Text>{t('m_label5')}</Text>
    <Text>{t('m_label6')}</Text>
    <Text>{t('m_label7')}</Text>
    <Text>{t('m_label8')}</Text>

    <Text marginTop={2}>{t('m_label9')}</Text>
    <Text>{t('m_label10')}</Text>
    <Text>{t('m_label11')}</Text>
    <Text>{t('m_label12')}</Text>
    <Text>{t('m_label13')}</Text>
    <Text>{t('m_label14')}</Text>
    <Text>{t('m_label15')}</Text>
    <Text>{t('m_label16')}</Text>
    <Text>{t('m_label17')}</Text>
    <Text>{t('m_label18')}</Text>
    <Text>{t('m_label19')}</Text>

    <Text marginTop={2}>{t('m_label20')}</Text>
    <Text>{t('m_label21')}</Text>
  </Box>;
}
