import {AntDesign, FontAwesome5} from '@expo/vector-icons';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import * as React from 'react';
import {BottomTabParamList} from "../Types";
import HistoryNavigator from "../screens/History";
import WelcomeNavigator from "../screens/Welcome";
import FormNavigator from "../screens/Form";
import {Platform} from "react-native";
import {Box, Text} from "native-base";
import {LabelPosition} from "@react-navigation/bottom-tabs/lib/typescript/src/types";
import {useTranslation} from "react-i18next";
import {useEquipment} from "../providers/EquipmentProvider";
import EquipmentDetailScreen from "../screens/Form/EquipmentDetailScreen";
import {ScreenOptions} from "../screens";
import {useSafeAreaInsets} from "react-native-safe-area-context";

function TabBarIcon(props: { name: React.ComponentProps<typeof AntDesign>['name'] | "head-side-mask"; color: string }) {
  if (props.name === 'head-side-mask') {
    return <FontAwesome5 size={25} {...props} />
  }

  // @ts-ignore
  return <AntDesign size={25} {...props} />;
}

function TabBarLabel(props: {
  color: string;
  focused: boolean;
  position: LabelPosition,
  children: React.ReactNode;
}) {
  if (props.position === 'beside-icon') {
    return (
      <Box flexDirection='row' alignItems='center' marginLeft={2}>
        <Text color={props.color} fontWeight='semibold'>{props.children}</Text>
      </Box>
    );
  }

  return <Box marginBottom={2} borderBottomWidth={props.focused ? 3 : 0} borderBottomColor={props.color}>
    <Text color={props.color} fontWeight='semibold' fontSize={12}>{props.children}</Text>
  </Box>;
}

const BottomTab = createBottomTabNavigator<BottomTabParamList>();
export default function BottomTabNavigator() {
  const {t} = useTranslation('Navigation');
  const {equipment} = useEquipment();
  const insets = useSafeAreaInsets();

  return (
    <BottomTab.Navigator
      initialRouteName="WelcomeStack"
      screenOptions={{
        tabBarActiveTintColor: '#108461',
        tabBarStyle: {
          height: 60 + insets.bottom,
        },
        tabBarLabelPosition: 'below-icon',
        headerShown: false,
      }}
    >
      <BottomTab.Screen
        name="WelcomeStack"
        component={WelcomeNavigator}
        options={{
          tabBarLabel: ({color, focused, position}) => <TabBarLabel color={color} focused={focused} position={position}>{t('main')}</TabBarLabel>,
          tabBarIcon: ({color}) => <TabBarIcon name="home" color={color}/>,
        }}
      />
      <BottomTab.Screen
        name="FormStack"
        component={FormNavigator}
        options={{
          tabBarLabel: ({color, focused, position}) => <TabBarLabel color={color} focused={focused} position={position}>{t('params')}</TabBarLabel>,
          tabBarIcon: ({color}) => <TabBarIcon name="form" color={color}/>,
        }}
      />

      {equipment && <BottomTab.Screen
        name="EquipmentDetail"
        component={EquipmentDetailScreen}
        options={{
          ...ScreenOptions,
          tabBarLabel: ({color, focused, position}) => <TabBarLabel color={color} focused={focused} position={position}>{t('equipment')}</TabBarLabel>,
          tabBarIcon: ({color}) => <TabBarIcon name="head-side-mask" color={color}/>,
          headerShown: Platform.OS !== 'web',
          title: equipment.name ?? t('EquipmentDetailScreen:title'),
          headerTitle: equipment.name ?? t('EquipmentDetailScreen:title'),
        }}
      />}

      <BottomTab.Screen
        name="HistoryStack"
        component={HistoryNavigator}
        options={{
          tabBarLabel: ({color, focused, position}) => <TabBarLabel color={color} focused={focused} position={position}>{t('history')}</TabBarLabel>,
          tabBarIcon: ({color}) => <TabBarIcon name="calendar" color={color}/>,
        }}
      />
    </BottomTab.Navigator>
  );
}
