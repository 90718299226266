import SelectMain from './Select';
import SelectItem from "native-base/src/components/primitives/Select/SelectItem";
import {ISelectComponentType} from "native-base/src/components/primitives/Select/types";

const SelectTemp: any = SelectMain;
SelectTemp.Item = SelectItem;

const Select = SelectTemp as ISelectComponentType;

export default Select;
export type { ISelectComponentType };
