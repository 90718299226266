import {Box} from "native-base";
import React, {useContext} from "react";
import {IAccordionContextProps, IAccordionItemProps} from "./types";
import { AccordionContext, AccordionItemContext } from './Context';

export default function AccordionItem({children, index: pIndex = 0}: IAccordionItemProps) {
  const {
    index: cIndex,
    changeHandler
  }: IAccordionContextProps = useContext(AccordionContext);
  const isOpen = cIndex === pIndex;

  const onOpen = (): void => {
    changeHandler && changeHandler(pIndex);
  }

  return <AccordionItemContext.Provider
    value={{
      index: pIndex,
      isOpen,
      onOpen
    }}
  >
    <Box borderWidth={1} borderRadius='lg' borderColor={isOpen ? 'primary.500' : 'gray.200'} paddingY={2} paddingX={4}>
      {children}
    </Box>
  </AccordionItemContext.Provider>;
}
