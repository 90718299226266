import {Box, HStack, Image, Link, Stack, Text, useMediaQuery} from "native-base";
import React from "react";
import LanguagePicker from "../../screens/Welcome/components/LanguagePicker";
import {useNavigation} from "@react-navigation/native";
import {NavigationProp} from "@react-navigation/core/src/types";
import {BottomTabParamList} from "../../Types";
import {useTranslation} from "react-i18next";

const logo = require('../../assets/logo.jpg');

export default function Header() {
  const {navigate} = useNavigation<NavigationProp<BottomTabParamList>>();
  const {t} = useTranslation('Web');
  const [isSmallScreen] = useMediaQuery({
    minWidth: 0,
    maxWidth: 800
  });

  const onLogoPress = () => {
    return navigate('WelcomeStack', {
      screen: 'Welcome'
    });
  }

  return <Stack direction={isSmallScreen ? 'column' : 'row'} flex={1} flexGrow={0} minHeight={isSmallScreen ? 220 : 100}
                height={isSmallScreen ? 200 : 100} shadow={1} paddingX={5} paddingY={4}>
    <Box justifyContent='center' paddingRight={isSmallScreen ? 0 : 4} borderRightWidth={isSmallScreen ? 0 : 1}
         borderRightColor='#DADADA' alignItems={isSmallScreen ? 'center' : 'flex-start'}>
      <Link onPress={onLogoPress}>
        <Image source={logo} width={64} height={90} alt={t('ciop')}/>
      </Link>
    </Box>

    <Box justifyContent='center' alignItems={isSmallScreen ? 'center' : 'flex-start'} paddingLeft={isSmallScreen ? 0 : 4} marginTop={isSmallScreen ? 2 : 0}>
      <Text fontWeight='600' fontSize='sm'>{t('appHeader')}</Text>
      <Text fontWeight='600' fontSize='sm'>{t('appHeader2')}</Text>
    </Box>

    <Box marginLeft={isSmallScreen ? 0 : 'auto'} justifyContent='center' alignItems={isSmallScreen ? 'center' : 'flex-start'}>
      <LanguagePicker/>
    </Box>
  </Stack>
}
