import React, {forwardRef, memo, useState} from "react";
import {Button} from "native-base";
import {IButtonProps} from "native-base/src/components/primitives/Button/types";
import {IBoxProps} from "native-base/src/components/primitives/Box/index";
import {IStackProps} from "native-base/src/components/primitives/Stack/index";

export interface IGradientButtonProps extends IButtonProps {
  _gradientStack?: IStackProps;
}

const GradientButton = (props: IGradientButtonProps & IBoxProps, ref: any) => {
  const [pressed, setPressed] = useState(false);

  return <Button
    p={0}
    onPressIn={()=>setPressed(true)}
    onPressOut={()=>setPressed(false)}
    _stack={{
      // @ts-ignore
      ...props._gradientStack,
      justifyContent: 'center',
      px: props?.size === 'lg' ? 10 : 5,
      py: props?.size === 'lg' ? 4 : 3,
      rounded: 'lg',
      bg:{
        linearGradient: {
          colors: pressed ? ['secondary.500', 'primary.500'] : ['primary.500', 'secondary.500'],
          start: [0, 0],
          end: [0, 1],
        },
      },
      ...props._stack,
    }}
    _text={{
      color: 'white',
      ...props._text,
    }}
    variant='unstyled'
    rounded="lg"
    {...props}
  >
    {props.children}
  </Button>;
}

export default GradientButton;
