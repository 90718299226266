import React, {forwardRef, useEffect, useRef} from 'react';
import {Platform, Keyboard, FlatList, TextInput} from 'react-native';
import { useFocusRing } from '@react-native-aria/focus';
import { useHover } from '@react-native-aria/interactions';
import {
  Actionsheet,
  Box,
  ChevronDownIcon,
  IButtonProps, Input,
  ISelectItemProps,
  ISelectProps, Pressable,
  useControllableState,
  usePropsResolution
} from "native-base";
import {useFormControl} from "native-base/src/components/composites/FormControl";
import {extractInObject, stylingProps} from "native-base/src/theme/v33x-theme/tools";
import {mergeRefs} from "native-base/src/utils";

export const SelectContext = React.createContext({
  onValueChange: (() => {}) as any,
  selectedValue: null as any,
  _selectedItem: {} as IButtonProps,
  _item: {} as IButtonProps,
});

const Select = (
  {
    isHovered: isHoveredProp,
    isFocused: isFocusedProp,
    isFocusVisible: isFocusVisibleProp,
    ...props
  }: ISelectProps,
  ref: any
) => {
  const selectProps = useFormControl({
    isDisabled: props.isDisabled,
    nativeID: props.nativeID,
  });

  const isDisabled = selectProps.disabled;

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);

  const { focusProps, isFocusVisible } = useFocusRing();

  const {
    onValueChange,
    selectedValue,
    children,
    dropdownIcon,
    dropdownCloseIcon,
    dropdownOpenIcon,
    placeholder,
    accessibilityLabel,
    defaultValue,
    _item,
    _selectedItem,
    onOpen,
    onClose,
    customDropdownIconProps,
    _actionSheetContent,
    _actionSheetBody,
    _webSelect,
    ...resolvedProps
  } = usePropsResolution(
    'Select',
    props,
    {
      isDisabled,
      isFocused: isFocusedProp || isFocused,
      isFocusVisible: isFocusVisibleProp || isFocusVisible,
    },
    undefined
  );

  const flatListData: ISelectItemProps[] = [
    {label: placeholder, value: '-1'}
  ];

  const [value, setValue] = useControllableState({
    value: selectedValue,
    defaultValue,
    onChange: (newValue) => {
      onValueChange && onValueChange(newValue);
      setIsOpen(false);
    },
  });

  const itemsList: Array<{ label: string; value: string }> = React.Children.map(
    children ?? [],
    (child: any) => {
      return {
        label: child.props.label,
        value: child.props.value,
      };
    }
  );

  const selectedItemArray = itemsList.filter(
    (item: any) => item.value === value
  );

  const selectedItem =
    selectedItemArray && selectedItemArray.length ? selectedItemArray[0] : null;

  const rightIcon =
    isOpen && dropdownOpenIcon ? (
      dropdownOpenIcon
    ) : !isOpen && dropdownCloseIcon ? (
      dropdownCloseIcon
    ) : dropdownIcon ? (
      dropdownIcon
    ) : (
      <ChevronDownIcon {...customDropdownIconProps} />
    );

  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  React.Children.map(children, (child: any) => {
    flatListData.push(child.props);
  });

  const [layoutProps] = extractInObject(resolvedProps, [
    ...stylingProps.margin,
    ...stylingProps.flexbox,
    ...stylingProps.position,
    'shadow',
    'opacity',
  ]);


  const commonInputRef = useRef<TextInput>(null);
  const commonInput = (
    <Input
      placeholder={placeholder}
      InputRightElement={rightIcon}
      {...resolvedProps}
      isFocused={isFocused}
      aria-hidden={true}
      importantForAccessibility="no"
      value={value == -1 || value == null ? null : selectedItem?.label}
      editable={false}
      focusable={false}
      isDisabled={isDisabled}
      pointerEvents="none"
      ref={commonInputRef}
    />
  );
  useEffect(() => {
    if ((value == '-1' || value == null)) {
      commonInputRef?.current?.clear()
    }
  }, [value]);

  return Platform.OS === 'web' ? (
    <Box>
      <select
        aria-readonly={selectProps.readOnly}
        required={selectProps.required}
        disabled={isDisabled}
        {...focusProps}
        ref={ref}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={selectedItem === null ? -1 : value}
        aria-label={placeholder}
        onFocus={() => {
          setIsFocused(true);
          onOpen && onOpen();
        }}
        onBlur={() => {
          setIsFocused(false);
          onClose && onClose();
        }}
        {..._webSelect}
      >
        <option value={'-1'}>{placeholder}</option>
        {children}
      </select>
      {commonInput}
    </Box>
  ) : (
    <>
      <Pressable
        onPress={() => {
          Keyboard.dismiss();
          setIsOpen(true);
          onOpen && onOpen();
        }}
        disabled={isDisabled}
        accessibilityLabel={accessibilityLabel}
        accessibilityRole="button"
        ref={ref}
        {...layoutProps}
      >
        {commonInput}
      </Pressable>
      <Actionsheet isOpen={isOpen} onClose={handleClose}>
        <Actionsheet.Content {..._actionSheetContent}>
          <FlatList
            {..._actionSheetBody}
            data={flatListData}
            keyExtractor={(_item, index) => index.toString()}
            style={{width: '100%'}}
            renderItem={({ item }: any) => {
              const isSelected = selectedValue === item.value;
              return (
                <Actionsheet.Item
                  onPress={() => {
                    if (!isDisabled) {
                      setValue(item.value);
                    }
                  }}
                  accessibilityState={{ selected: isSelected }}
                  {...item}
                  {..._item}
                  {...(isSelected && _selectedItem)}
                >
                  {item.label}
                </Actionsheet.Item>
              );
            }}
          />
        </Actionsheet.Content>
      </Actionsheet>
    </>
  );
};

export default forwardRef(Select);
