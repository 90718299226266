import {Box, Button, useTheme} from "native-base";
import React, {useCallback, useEffect, useRef, useState} from "react";
import ActionsheetStop from "./components/ActionsheetStop";
import {States} from "../../enums/StatesEnum";
import Clock from "./components/Clock";
import ClockButton from "./components/ClockButton";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import {NavigationProp} from "@react-navigation/core/src/types";
import {BottomTabParamList} from "../../Types";
import * as Notifications from "expo-notifications";
import * as Linking from "expo-linking";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {API_URL, STORAGE} from "../../consts";
import AppLoading from "expo-app-loading";
import Header from "./components/Header";
import {HistoryItem} from "../History/HistoryScreen";
import {isNumber, isUndefined} from "lodash";
import {useEquipment} from "../../providers/EquipmentProvider";
import {useTimer} from "../../providers/TimerProvider";
import {useTranslation} from "react-i18next";
import {AppState} from "react-native";

export default function TimerScreen() {
  const {timer, setTimer, stopTimer} = useTimer();

  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [isActionSheetVisible, setIsActionSheetVisible] = useState(false);
  const {gradient} = useTheme();
  const {navigate} = useNavigation<NavigationProp<BottomTabParamList>>();

  const [isLoading, setIsLoading] = useState(true);
  const {equipment} = useEquipment();

  const {t} = useTranslation('TimerScreen');

  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);

  useEffect(() => {
    if (timer) {
      setIsLoading(false);
    }
  }, [timer]);

  useEffect(() => {
    if (!isLoading && timer) {
      let timeleft;

      clearAllNotifications();
      if (timer.state === States.RUNNING && timer.expiration) {
        console.log(new Date(timer.expiration).toString());

        const now = new Date().getTime();
        timeleft = Math.round((new Date(timer.expiration).getTime() - now) / 1000);

        if (timeLeft > 15 * 60) {
          notificationFifteenMinutes(
            new Date(now + (timeLeft - 60 * 15) * 1000)
          );
        }

        notificationNow(
          new Date(now + timeLeft * 1000)
        );
      } else if ((timer.state === States.PAUSED || timer.state === States.INITIAL) && timer.timeLeft) {
        timeleft = timer.timeLeft;
      } else {
        timeleft = 0;
      }

      if (timeleft <= 0) {
        setExpired();
      } else {
        setTimeLeft(timeleft);
      }
    }
  }, [isLoading, timer.state]);

  useEffect(() => {
    const appStateListener = AppState.addEventListener("change", nextAppState => {
      appState.current = nextAppState;
      setAppStateVisible(appState.current);
    });

    return () => {
      appStateListener?.remove();
    };
  }, []);


  useEffect(() => {
    if (appStateVisible === "active" && timer && timer.state === States.RUNNING) {
      setTimeLeft(Math.round((new Date(timer.expiration).getTime() - new Date().getTime()) / 1000));
    }
  }, [appStateVisible]);

  useFocusEffect(
    useCallback(() => {

    }, [isLoading, timer])
  );

  const setExpired = () => {
    if (timer) {
      setTimer({
        ...timer,
        timeLeft: 0,
        state: States.EXPIRED,
      });
    }

    setTimeLeft(0);
  }

  const onPressHandler = () => {
    if (timer) {
      let now = (new Date()).getTime();

      if (timer.state === States.RUNNING) {
        setTimer({
          expiration: new Date(now + (timeLeft ?? 0) * 1000),
          timeLeft: timeLeft,
          state: States.PAUSED,
        });
      } else if (timer.state === States.PAUSED || timer.state === States.INITIAL) {
        setTimer({
          expiration: new Date(now + (timeLeft ?? 0) * 1000),
          timeLeft: timeLeft,
          state: States.RUNNING,
        });
      } else {
        setTimer({
          expiration: timer.expiration,
          timeLeft: 0,
          state: States.EXPIRED,
        });
      }
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (timer) {
      interval = setInterval(() => {
        if (timer.state === States.RUNNING) {
          setTimeLeft(c => {
            if (isUndefined(c) || c <= 0) {
              clearInterval(interval);
            }

            return isUndefined(c) ? 0 : --c;
          });

          if (timeLeft <= 0) {
            setExpired();
          }
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    }
  }, [timer.state]);

  const clearAllNotifications = async () => {
    console.log('Clear notifications');

    await Notifications.cancelAllScheduledNotificationsAsync();
  };

  const notificationFifteenMinutes = async (date: Date) => {
    console.log('Notification 15 set on ' + date.toString());

    return await Notifications.scheduleNotificationAsync({
      content: {
        title: t('notification15_title'),
        body: t('notification15_body'),
        data: {
          url: Linking.createURL('/timer'),
        },
      },
      trigger: {
        date: date,
        channelId: 'default',
      },
    });
  }

  const notificationNow = async (date: Date) => {
    console.log('Notification now set on ' + date.toString());

    return await Notifications.scheduleNotificationAsync({
      content: {
        title: t('notificationnow_title'),
        body: t('notificationnow_body'),
        data: {
          url: Linking.createURL('/timer'),
        }
      },
      trigger: {
        date: date,
        channelId: 'default',
      },
    });
  }

  const onStopHandler = async () => {
    await clearAllNotifications();
    await saveToHistory();
    setIsActionSheetVisible(false);
    await stopTimer();

    setTimeout(() => {
      navigate('HistoryStack');
    });
  };

  const saveToHistory = async () => {
    let mcu = await AsyncStorage.getItem(STORAGE.MCU);
    let mcu_seconds = mcu ? parseInt(mcu) : null;
    let result = await AsyncStorage.getItem(STORAGE.HISTORY);

    const name = equipment && t(equipment.index, {
      defaultValue: equipment.name,
      ns: 'EquipmentDetailScreen',
    });

    let item: HistoryItem = {
      equipment: name ?? '',
      date: new Date(),
      time: (mcu_seconds ?? 8 * 60 * 60) - (timeLeft ?? 0),
    };

    let history: HistoryItem[];
    if (result != null) {
      history = JSON.parse(result);
    } else {
      history = [];
    }

    history.unshift(item);
    await AsyncStorage.setItem(STORAGE.HISTORY, JSON.stringify(history));

    let form_text = await AsyncStorage.getItem(STORAGE.FORM);
    let form_data;
    if (form_text != null) {
      form_data = JSON.parse(form_text);
    }

    let form = {
      code: equipment?.code ?? '',
      date: (new Date()).toISOString(),
      time: ((mcu_seconds ?? 8 * 60 * 60) - (timeLeft ?? 0)).toString(),
      breaks: (0).toString(),
      workplace: form_data?.workplace == '-1' ? null : (form_data?.workplace ?? null),
      environment: form_data?.environment == '-1' ? null : (form_data?.environment ?? null),
      oxygen: form_data?.oxygen == '-1' ? null : (form_data?.oxygen ?? null),
      gas_steam_presence: form_data?.gas_steam_presence == '-1' ? null : (form_data?.gas_steam_presence ?? null),
      temp: form_data?.temp == '-1' ? null : (form_data?.temp ?? null),
      humidity: form_data?.humidity == '-1' ? null : (form_data?.humidity ?? null),
      dust: form_data?.dust == '-1' ? null : (form_data?.dust ?? null),
      beard: form_data?.beard === true ? 0 : 1,
      irregular_face: form_data?.irregular_face === true ? 0 : 1,
      glasses: form_data?.glasses === true ? 0 : 1,
      micro_risk_group: form_data?.micro_risk_group == '-1' ? null : (form_data?.micro_risk_group ?? null),
      micro_risk_num: form_data?.micro_risk_num == '-1' ? null : (form_data?.micro_risk_num ?? null),
      metabolites_air: form_data?.metabolites_air == '-1' ? null : (form_data?.metabolites_air ?? null),
      metabolites_dust: form_data?.metabolites_dust == '-1' ? null : (form_data?.metabolites_dust ?? null),
      metabolites_air_num: form_data?.metabolites_air_num == '-1' ? null : (form_data?.metabolites_air_num ?? null),
      metabolites_dust_num: form_data?.metabolites_dust_num == '-1' ? null : (form_data?.metabolites_dust_num ?? null),
      cytotoxicity_dust: form_data?.cytotoxicity_dust == '-1' ? null : (form_data?.cytotoxicity_dust ?? null),
      biodiversity_air: form_data?.biodiversity_air == '-1' ? null : (form_data?.biodiversity_air ?? null),
      biodiversity_dust: form_data?.biodiversity_dust == '-1' ? null : (form_data?.biodiversity_dust ?? null),
    }

    fetch(API_URL, {
      method: 'POST',
      body: JSON.stringify(form),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    });
  }

  if (isLoading) {
    return <AppLoading/>;
  }

  return <Box
    flex={1}
    bg={(timer && timer.state === States.EXPIRED) ? '#EA3131' : {linearGradient: gradient}}
    paddingX={[16, 48]}
    justifyContent='center'
    safeArea>

    <Header/>

    {(timer && isNumber(timeLeft)) && <Clock timer={timer} timeLeft={timeLeft}/>}
    {(timer && timer.state !== States.EXPIRED) && <ClockButton timer={timer} onPress={onPressHandler}/>}

    <Button
      bg='white'
      _text={{color: (timer && timer.state === States.EXPIRED) ? '#EA3131' : 'secondary.500'}}
      _hover={{bg: '#e7e5e4'}}
      _pressed={{bg: '#d6d3d1'}}
      size='lg'
      onPress={() => setIsActionSheetVisible(true)}
    >
      {t('finish')}
    </Button>

    <ActionsheetStop
      isOpen={isActionSheetVisible}
      onStop={onStopHandler}
      onClose={() => setIsActionSheetVisible(false)}
    />
  </Box>;
}

