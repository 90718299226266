import {ChevronDownIcon, ChevronUpIcon, Pressable} from "native-base";
import React, {useContext} from "react";
import {IAccordionItemContextProps, IAccordionSummaryProps} from "./types";
import {AccordionItemContext} from "./Context";

export default function AccordionSummary({children}: IAccordionSummaryProps) {
  const {
    isOpen,
    onOpen
  }: IAccordionItemContextProps = useContext(AccordionItemContext);

  return <Pressable onPress={onOpen} paddingY={3} flexDirection='row' alignItems='center' justifyContent='space-between'>
    {children}
    {isOpen ?  <ChevronDownIcon /> : <ChevronUpIcon />}
  </Pressable>;
}
