import {ImageURISource} from "react-native";
import React from "react";
import M from "./M";
import {ProtectionClasses} from "../forms/EquipmentForm";

class M2 extends M {
  readonly name: string = 'maska z filtrami klasy P2';
  readonly image: ImageURISource = require('../assets/equipment/M.jpg');
  readonly protectionClass: ProtectionClasses = ProtectionClasses.P2;

  readonly code: string = 'P2';
  readonly index: string = 'M2';
  readonly oil: string = '6';
  readonly aerosol: string = '6';
}

export default new M2();
