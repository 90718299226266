export const MCU = 8;  // maksymalny czas użytkowania - w godzinach
export const TEMP_LEVELS = {
  'low': 20,
  'high': 25
};
export const HUMIDITY_LEVELS = {
  'low': 50,
  'high': 75
};
export const OXYGEN_LEVEL = 19;
export const DUST_NDS = {
  'PM1': 0.01,
  'PM2_5': 0.02,
  'PM4': 0.04,
  'TOTAL': 0.01  // total suspended particles
};
export const MICRO_NDS = {
  'indoor': {
    'mesophiles': 100000,
    'gram_negative_bacteria': 20000,
    'thermoactinomycetes': 20000,
    'fungi': 50000
  },
  'outdoor': {
    'bacteria': 5000,
    'gram_negative_bacteria': 200,
    'thermoactinomycetes': 200,
    'fungi': 5000
  }
};
export const BACTERIAL_ENDOTOXIN_AIR_NDS = 200;  // ng/m3
export const BACTERIAL_ENDOTOXIN_DUST_NDS = 0.155;  // nmol LPS mg-1
