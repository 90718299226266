import React from 'react';
import {
  Box,
  Center,
  Heading,
  HStack,
  Image,
  ScrollView,
  Text,
  VStack,
  Hidden,
  View,
  Link,
  useMediaQuery
} from 'native-base';
import GradientButton from "../../components/GradientButton";
import {useTranslation} from 'react-i18next';
import LanguagePicker from "./components/LanguagePicker";
import {useNavigation} from "@react-navigation/native";
import {BottomTabParamList} from "../../Types";
import {NavigationProp} from "@react-navigation/core/src/types";
import ContentWrapper from "../../components/ContentWrapper";
import {Platform} from "react-native";
import {GPLAY_URL} from "../../consts";
import * as Application from 'expo-application';
import * as Updates from 'expo-updates';

const logo = require('../../assets/logo.jpg');

export default function WelcomeScreen() {
  const { t, i18n } = useTranslation('WelcomeScreen');
  const { navigate } = useNavigation<NavigationProp<BottomTabParamList>>();
  const [isSmallScreen] = useMediaQuery({
    minWidth: 0,
    maxWidth: 800
  });

  const onPressHandler = () => {
    navigate('FormStack', {screen: 'Form'});
  }

  const openAppStore = () => {

  }

  const openGPlay = () => {
    if (Platform.OS === 'web') {
      window.open(GPLAY_URL, '_blank');
    }
  }

  const checkUpdates = () => {
    Updates.checkForUpdateAsync()
      .then(({ isAvailable }) => {
        alert(isAvailable ? 'Aktualizacja dostępna.' : 'Brak nowych aktualizacji');

        if (isAvailable) {
          Updates.fetchUpdateAsync()
            .then(({ isNew }) => {
              if (isNew) {
                Updates.reloadAsync()
              }
            })
        }
      }).catch(error => {
      alert(error);
    });
  }

  return (
    <ContentWrapper>
      <Box safeArea>
        <VStack space={4}>
          <Hidden platform={['web']}>
            <View marginTop={4}>
              <LanguagePicker />

              <Center marginTop={6}>
                <Image source={logo} width={200} height={86} alt='Centralny Instytut Ochrony Pracy - Państwowy Instytut Badawczy' />
              </Center>
            </View>
          </Hidden>

          <Heading size='lg'>{t('header')}</Heading>
          <Text textAlign='justify'>{t('description')}</Text>

          <Heading size='lg'>{t('howItWorks')}</Heading>
          <VStack space={2}>
            <HStack space={1} alignItems='center'>
              <Box {...params.point}>
                <Text color='primary.500'>{1}</Text>
              </Box>
              <Text fontWeight='semibold' paddingRight={5} fontSize='sm'>{t('howItWorksPoints.0')}</Text>
            </HStack>

            <HStack space={1} alignItems='center'>
              <Box {...params.point}>
                <Text color='primary.500'>{2}</Text>
              </Box>
              <Text fontWeight='semibold' paddingRight={5} fontSize='sm'>{t('howItWorksPoints.1')}</Text>
            </HStack>

            <Hidden platform={['web']}>
              <HStack space={1} alignItems='center'>
                <Box {...params.point}>
                  <Text color='primary.500'>{3}</Text>
                </Box>
                <Text fontWeight='semibold' paddingRight={5} fontSize='sm'>{t('howItWorksPoints.2')}</Text>
              </HStack>
            </Hidden>
          </VStack>

          {/* @ts-ignore */}
          <GradientButton
            _gradientStack={{flex: 1}}
            size='lg'
            onPress={onPressHandler}
          >
            {t('start')}
          </GradientButton>

          <Hidden platform={['android', 'ios']}>
            <Box bg='#E8FDF7' borderWidth={1} borderColor='#A5F4DD' borderRadius='md' padding={5}>
              <Box>
                <Text>{t('download')}</Text>
              </Box>

              {i18n.language === 'en'
                ? <HStack marginTop={4} space={4}>
                  <Link onPress={openAppStore}>
                    <Image width={isSmallScreen ? 102 : 135} height={isSmallScreen ? 34 : 45} source={require('../../assets/download_appstore_en.svg')} alt={t('download_appstore')} />
                  </Link>
                  <Link onPress={openGPlay}>
                    <Image width={isSmallScreen ? 116 : 180} height={isSmallScreen ? 34 : 47} source={require('../../assets/download_gplay_en.png')} alt={t('download_gplay')} />
                  </Link>
                  </HStack>
                : <HStack marginTop={4} space={4}>
                  <Link onPress={openAppStore}>
                    <Image width={isSmallScreen ? 102 : 135} height={isSmallScreen ? 34 : 45} source={require('../../assets/download_appstore.svg')} alt={t('download_appstore')} />
                  </Link>
                  <Link onPress={openGPlay}>
                    <Image width={isSmallScreen ? 116 : 155} height={isSmallScreen ? 34 : 45} source={require('../../assets/download_gplay.png')} alt={t('download_gplay')} />
                  </Link>
                  </HStack>
              }
            </Box>
          </Hidden>

          <Hidden platform={['web']}>
            <Text fontSize='2xs' color='gray.500' textAlign='justify'>{t('info')}</Text>
          </Hidden>

          <Hidden platform={['web']}>
            <View>
            </View>
            <Text fontSize='2xs' color='gray.500' onPress={checkUpdates}>Wersja aplikacji: {Application.nativeApplicationVersion} ({Updates.releaseChannel} - {Updates.updateId})</Text>
          </Hidden>
        </VStack>
      </Box>
    </ContentWrapper>
  );
}

const params = {
  point: {
    borderColor: 'primary.500',
    borderWidth: 1,
    borderRadius: 20,
    w: 8,
    h: 8,
    lineHeight: 6,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 2,
  }
};
