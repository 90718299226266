// @ts-ignore

import {createStackNavigator} from "@react-navigation/stack";
import * as React from "react";
import WelcomeScreen from "./WelcomeScreen";
import {ScreenOptions} from "../index";
import {useTranslation} from "react-i18next";

export type WelcomeParamList = {
  Welcome: undefined;
}

// @ts-ignore
let WelcomeStack;
let options;

WelcomeStack = createStackNavigator<WelcomeParamList>();
options = ScreenOptions;

export default function WelcomeNavigator() {
  const { t } = useTranslation();

  return (
    // @ts-ignore
    <WelcomeStack.Navigator>
      {/*@ts-ignore*/}
      <WelcomeStack.Screen
        name="Welcome"
        component={WelcomeScreen}
        options={{
          title: t('WelcomeScreen:title'),
          headerShown: false,
        }}
      />
      {/*@ts-ignore*/}
    </WelcomeStack.Navigator>
  );
}
