import {VStack} from "native-base";
import React, {useState} from "react";
import {IAccordionProps} from "./types";
import { AccordionContext } from './Context';
import getIndexedChildren from "native-base/src/utils/getIndexedChildren";

const Accordion = ({children}: IAccordionProps) => {
  const [index, setIndex] = useState(-1);

  const changeHandler = (newIndex: number) => {
    (newIndex === index) ? setIndex(-1) : setIndex(newIndex);
  };

  return <AccordionContext.Provider
    value={{
      index: index,
      changeHandler,
    }}
  >
    <VStack space={2}>
      {getIndexedChildren(children)}
    </VStack>
  </AccordionContext.Provider>;
}

export default Accordion;
