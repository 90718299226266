import i18n from "i18next";
import {initReactI18next} from "react-i18next";

const { LanguageDetectorPlugin } = require('./LanguageDetectorPlugin');

const resources = {
  pl: {
    Navigation: {
      main: 'Główna',
      params: 'Parametry',
      history: 'Historia',
      equipment: 'Urządzenie',
    },
    WelcomeScreen: {
      title: 'Strona główna',
      header: "Dzień dobry",
      description: "Ta aplikacja pomoże Ci dobrać odpowiedni sprzęt ochrony układu oddechowego. Dzięki opcji timera, już nigdy nie zapomnisz o jego wymianie zgodnie z zaleceniami producenta.",
      howItWorks: "Jak to działa?",
      howItWorksPoints: [
        "Wypełnij formularz",
        "Sprawdź rekomendacje i wybierz sprzęt ochrony układu oddechowego",
        "Użyj timera, aby kontrolować czas użycia",
      ],
      start: "Zaczynamy!",
      info: "Opracowano na podstawie wyników V etapu programu wieloletniego „Poprawa bezpieczeństwa i warunków pracy”, finansowanego w latach 2021-2022 w zakresie zadań służb państwowych ze środków Ministra Rodziny i Polityki Społecznej (zadanie nr 3.SP.07 pt. „Aplikacja użytkowa do doboru sprzętu ochrony układu oddechowego przed bioaerozolem w wybranych środowiskach pracy”). Koordynator programu: Centralny Instytut Ochrony Pracy – Państwowy Instytut Badawczy",
      download: 'Możesz też pobrać aplikację na telefon. Wersja na urządzenia mobilne posiada dodatkowo timer, który poinformuje Cie o konieczności wymiany SOUO.',
      download_appstore: 'Pobierz z App Store',
      download_gplay: 'Pobierz z Google Play',
      ciop: 'Centralny Instytut Ochrony Pracy - Państwowy Instytut Badawczy',
    },
    FormScreen: {
      title: 'Wypełnij formularz',
      select: 'Wybierz',
      select_item: '-- Usuń wybór --',
      less: 'Chcę podać mniej danych',
      more: 'Chcę podać więcej danych',
      show_recommended: 'Pokaż rekomendowane SOUO',
      clear_form: 'Wyczyść formularz',
      labels: {
        workplace: 'Środowisko pracy',
        environment: 'Miejsce wykonywania pracy',
        oxygen: 'Zawartość tlenu',
        gas_steam_presence: 'Obecność par i gazów w otoczeniu',
        temp: 'Temperatura',
        humidity: 'Wilgotność',
        dust: 'Stężenie pyłu',
        beard: 'Posiadam zarost',
        irregular_face: 'Posiadam nieregularne rysy twarzy',
        glasses: 'Noszę okulary korekcyjne',
        micro_risk_group: 'Rodzaj mikroorganizmów',
        micro_risk_num: 'Ilość mikroorganizmów',
        metabolites_air: 'Rodzaj metabolitów w powietrzu',
        metabolites_dust: 'Rodzaj metabolitów w pyle',
        metabolites_air_num: 'Ilość metabolitów w powietrzu',
        metabolites_dust_num: 'Ilość metabolitów w pyle',
        cytotoxicity_dust: 'Cytotoksyczność pyłu',
        biodiversity_air: 'Bioróżnorodność powietrza',
        biodiversity_dust: 'Bioróżnorodność pyłu',
      },
      options: {
        agriculture: 'Rolnictwo',
        sewage: 'Oczyszczalnia ścieków',
        waste: 'Sortownia odpadów',
        indoor: 'W pomieszczeniu',
        outdoor: 'Na zewnątrz',
        yes: 'Tak',
        no: 'Nie',
        nds1: '<1xNDS',
        nds2: '<1-4)xNDS',
        nds3: '<4-9)xNDS',
        nds4: '<9-20)xNDS',
        nds5: '>=20xNDS',
        microriskGroup1: 'Mikroorganizmy z 1 grupy ryzyka',
        microriskGroup2: 'Mikroorganizmy z 2 grupy ryzyka',
        microriskGroup3: 'Mikroorganizmy z 3 grupy ryzyka',
        microriskGroup4: 'Mikroorganizmy z 4 grupy ryzyka',
        microNum1: '< wartość graniczna',
        microNum2: '<1-4)x wartość graniczna',
        microNum3: '<4-9)x wartość graniczna',
        microNum4: '<9-20)x wartość graniczna',
        microNum5: '>=20x wartość graniczna',
        none: 'Brak',
        endotoxin: 'Endotoksyna obecna',
        mold: 'Metabolity wtórne pleśni obecne',
        other: 'Inne metabolity pochodzenia mikrobiologicznego obecne',
        metabolitesAirNumLow: 'Endotoksyna bakteryjna <200 ng/m3',
        metabolitesAirNumHigh: 'Endotoksyna bakteryjna >200 ng/m3',
        metabolitesDustNumLow: 'Endotoksyna bakteryjna < 0,155 nmol LPS mg-1',
        metabolitesDustNumHigh: 'Endotoksyna bakteryjna > 0,155 nmol LPS mg-1',
        cytotoxicityLow: 'Niska (brak IC50 po 48h)',
        cytotoxicityHigh: 'Wysoka (wyznaczone IC50 po 48h)',
        biodiversityLow: 'Niska',
        biodiversityHigh: 'Wysoka',
      },
    },
    ResultsScreen: {
      title: 'Rekomendowane SOUO',
      check: 'Sprawdź',
      no_protection_class: 'Brak potrzeby stosowania sprzętu',
      insulating: 'Zastosuj sprzęt izolujący',
      absorbing: 'Zastosuj sprzęt pochłaniający',
    },
    EquipmentDetailScreen: {
      title: 'Sprzęt ochrony układu oddechowego',
      description: 'Opis podstawowy',
      howToUse: 'Jak używać?',
      warningsAndLabeling: 'Ostrzeżenia i znakowanie',
      warnings: 'Ostrzeżenia',
      labeling: 'Znakowanie',
      runTimer: 'Uruchom timer',

      FFP1: 'półmaska filtrująca klasy FFP1',
      FFP2: 'półmaska filtrująca klasy FFP2',
      FFP3: 'półmaska filtrująca klasy FFP3',
      M1: 'maska z filtrami klasy P1',
      M2: 'maska z filtrami klasy P2',
      M3: 'maska z filtrami klasy P3',
      P1: 'półmaska z filtrami klasy P1',
      P2: 'półmaska z filtrami klasy P2',
      P3: 'półmaska z filtrami klasy P3',
      THP1: 'sprzęt z wymuszonym przepływem powietrza klasy THP1',
      THP2: 'sprzęt z wymuszonym przepływem powietrza klasy THP2',
      THP3: 'sprzęt z wymuszonym przepływem powietrza klasy THP3',

      fpp_p1: 'Półmaska filtrująca składa się z połączonych na obrzeżu układów włóknin filtracyjnych. Osłania nos, usta i brodę użytkownika. Często, w celu poprawy warunków oddychania, montuje się w nich zawór wydechowy. Półmaski filtrujące są wyposażone w nagłowie, które stanowi układ taśm pasmanteryjnych, gumowych, lateksowych, zapinek lub zauszników. W półmaskach bez zaworu wydechowego przepływ powietrza w fazie wdechu i wydechu następuje przez materiał filtrujący, a w półmaskach z zaworem wydechowym przez materiał filtrujący i zawór wydechowy.',
      fpp_p2: 'Półmaski filtrujące powinny spełniać wymagania normy PN-EN 149 „Sprzęt ochronny układu oddechowego. Półmaski filtrujące do ochrony przed cząstkami. Wymagania, badania, znakowanie”.',
      fpp_p3: 'Półmaski filtrujące są klasyfikowane (i znakowane) w odniesieniu od ich skuteczności filtracji i maksymalnego dopuszczalnego całkowitego przecieku wewnętrznego.',
      fpp_p4: 'Maksymalna penetracja mgły oleju parafinowego:',
      fpp_p5: 'Maksymalna penetracja aerozolu chlorku sodu:',
      fpp_p6: 'Maksymalny całkowity przeciek wewnętrzny:',
      fpp_p7: '* Co najmniej 46 z 50 wyników indywidualnych CPW / co najmniej 8 z 10 średnich CPW dla każdego uczestnika nie powinno być większych niż podana wartość',
      fpp_warn1: '•	Półmaski filtrujące nie powinny być stosowane przez osoby posiadające zarost!',
      fpp_warn2: '•	Półmaski filtrujące nie powinny być stosowane przez osoby posiadające nieregularne rysy twarzy uniemożliwiające zachowanie szczelności dopasowania!',
      fpp_warn3: '•	Należy zadbać o kompatybilność półmaski filtrującej z innymi sprzętami ochrony układu oddechowego i okularami korekcyjnymi!',
      fpp_label1: 'Znakowanie półmasek filtrujących powinno obejmować, co najmniej:',
      fpp_label2: '•	nazwę producenta lub znak towarowy identyfikujący producenta,',
      fpp_label3: '•	adres producenta,',
      fpp_label4: '•	znakowanie identyfikujące typ półmaski,',
      fpp_label5: '•	symbol właściwej klasy ochrony,',
      fpp_label6: '•	numer serii lub datę produkcji,',
      fpp_label7: '•	numer i rok wydania właściwej normy polskiej lub europejskiej,',
      fpp_label8: '•	znak CE i czterocyfrowy numer jednostki notyfikowanej,',
      fpp_label9: '•	symbol NR, jeśli półmaska przeznaczona jest do użycia w ciągu jednej zmiany roboczej lub symbol R, jeśli półmaska przeznaczona jest do użycia wielokrotnego,',
      fpp_label10: '•	półmaski filtrujące, które pozytywnie przeszły badanie zatkania pyłem dolomit owym powinny być oznakowane literą D.',

      m_p1: 'Maska jest częścią twarzową pokrywającą oczy, nos, usta i podbródek. Posiada zawór lub zawory wydechowe ułatwiające oddychanie. Może być stosowana z 1 lub 2 elementami oczyszczającymi (np. filtrem, pochłaniaczem lub filtropochłaniaczem) lub aparatem oddechowym.',
      m_p2: 'Maski powinny spełniać wymagania normy PN-EN 136 „Sprzęt ochrony układu oddechowego. Maski. Wymagania, badanie, znakowanie”.',
      m_p3: 'Wyróżnia się trzy klasy masek:',
      m_p4: '•	Klasa 1: do lekkiego stosowania,',
      m_p5: '•	Klasa 2: do ogólnego stosowania,',
      m_p6: '•	Klasa 3: do specjalnego stosowania.',
      m_p7: 'Maski powinny być skompletowane z odpowiednim elementem oczyszczającym (filtr lub komplet filtrów).',
      m_p8: 'Filtry składają się z układu włóknin filtracyjnych połączonych ze sobą na obwodzie (filtry płaskie); luźnych włókien lub układu włóknin filtracyjnych, zamkniętych w obudowie umożliwiającej swobodny przepływ powietrza (filtry kapsułowane) lub włókniny plisowanej z otworem wylotowym i łącznikiem gwintowym lub bagnetowym (filtry kasułowane z łacznikiem). Zasada ich działania polega na wychwytywaniu cząstek zanieczyszczeń ze strumienia przepływającego powietrza przez materiał filtracyjny. Filtry dopiero po skompletowaniu z odpowiednią częścią stanowią sprzęt o zróżnicowanym stopniu skuteczności.',
      m_p9: 'Filtry powinny spełniać wymagania, zawarte w normach PN-EN 143 „Sprzęt ochronny układu oddechowego. Filtry. Wymagania, badanie, znakowanie”.',
      m_p10: 'Klasyfikacja filtrów odnosi się bezpośrednio do ich skuteczności filtracji dwóch aerozoli testowych: mgły oleju parafinowego oraz aerozolu chlorku sodu.',
      m_p11: 'Maksymalna penetracja mgły oleju parafinowego:',
      m_p12: 'Maksymalna penetracja aerozolu chlorku sodu:',
      m_warn1: 'Maski nie powinny być stosowane przez osoby posiadające zarost!',
      m_warn2: 'Maski nie powinny być stosowane przez osoby posiadające nieregularne rysy twarzy uniemożliwiające zachowanie szczelności dopasowania!',
      m_warn3: 'Należy zadbać o kompatybilność maski z innymi sprzętami ochrony układu oddechowego i okularami korekcyjnymi!',
      m_warn4: 'Maska powinna być skompletowana z odpowiednim elementem oczyszczającym!',
      m_label1: 'Znakowanie masek powinno obejmować, co najmniej:',
      m_label2: '•	nazwę producenta lub znak towarowy identyfikujący producenta,',
      m_label3: '•	adres producenta,',
      m_label4: '•	klasę maski poprzedzoną literami CL,',
      m_label5: '•	numer lub rok wydania właściwej normy polskiej lub europejskiej,',
      m_label6: '•	znak CE i czterocyfrowy numer jednostki notyfikowanej,',
      m_label7: '•	numer serii lub datę produkcji.',
      m_label8: 'Wszystkie części tego samego modelu powinny być oznakowane identycznie w przypadku danego typu.',
      m_label9: 'Filtry, których materiał filtrujący jest umieszczony wewnątrz obudowy (filtry kapsułowane) powinny wyróżniać się białą barwą obudowy lub białą etykietą oraz posiadać, co najmniej oznaczenie:',
      m_label10: '•	klasy i typu filtra,',
      m_label11: '•	informację, że filtr jest przeznaczony do sprzętu dwufiltrowego (jeśli dotyczy),',
      m_label12: '•	numer i rok wydania właściwej normy polskiej lub europejskiej,',
      m_label13: '•	przynajmniej rok dopuszczalnego okresu przechowywania,',
      m_label14: '•	nazwę producenta lub znak towarowy identyfikujący producenta,',
      m_label15: '•	adres producenta,',
      m_label16: '•	napis w oficjalnym języku kraju przeznaczenia: „Patrz informacje podane przez producenta” lub odpowiedni piktogram,',
      m_label17: '•	znak identyfikujący typ wyrobu,',
      m_label18: '•	znak CE i czterocyfrowy numer jednostki notyfikowanej,',
      m_label19: '•	numer serii lub datę produkcji.',
      m_label20: 'Filtry wykonane w całości z materiału filtracyjnego (bez obudowy) powinny mieć oznakowanie zawierające przynajmniej odpowiedni typ i klasę filtru oraz znak identyfikujący wyrób.',
      m_label21: 'Jeśli na filtrze nie umieszczono któregokolwiek z wyżej wymienionych oznaczeń powinno być ono uwzględnione na opakowaniu jednostkowym filtru. Bez konieczności otwierania opakowania powinny być widoczne informację o roku wygaśnięcia dopuszczalnego okresu przechowywania, znak identyfikujący wyrób i zalecane warunki przechowywania.',

      p_p1: 'Półmaska jest częścią twarzową pokrywającą nos, usta i podbródek. Posiada ona zawór lub zawory wydechowe ułatwiające oddychanie. Sprzęt ten może być przystosowany do kompletowania z 1 lub 2 elementami oczyszczającymi (np. filtrem, pochłaniaczem lub filtropochłaniaczem) montowanymi na łącznikach z zaworem wdechowym.',
      p_p2: 'Półmaski powinny spełniać wymagania normy PN-EN 140 „Sprzęt ochrony układu oddechowego. Półmaski i ćwierćmaski. Wymagania, badanie, znakowanie.”',
      p_p3: 'Półmaski powinny być skompletowane z odpowiednim elementem oczyszczającym (filtr lub komplet filtrów).',
      p_p4: 'Filtry składają się z układu włóknin filtracyjnych połączonych ze sobą na obwodzie (filtry płaskie); luźnych włókien lub układu włóknin filtracyjnych, zamkniętych w obudowie umożliwiającej swobodny przepływ powietrza (filtry kapsułowane) lub włókniny plisowanej z otworem wylotowym i łącznikiem gwintowym lub bagnetowym (filtry kasułowane z łacznikiem). Zasada ich działania polega na wychwytywaniu cząstek zanieczyszczeń ze strumienia przepływającego powietrza przez materiał filtracyjny. Filtry dopiero po skompletowaniu z odpowiednią częścią stanowią sprzęt o zróżnicowanym stopniu skuteczności.',
      p_p5: 'Filtry powinny spełniać wymagania, zawarte w normach PN-EN 143 „Sprzęt ochronny układu oddechowego. Filtry. Wymagania, badanie, znakowanie”.',
      p_p6: 'Klasyfikacja filtrów odnosi się bezpośrednio do ich skuteczności filtracji dwóch aerozoli testowych: mgły oleju parafinowego oraz aerozolu chlorku sodu.',
      p_p7: 'Maksymalna penetracja mgły oleju parafinowego:',
      p_p8: 'Maksymalna penetracja aerozolu chlorku sodu:',
      p_warn1: '•	Półmaski nie powinny być stosowane przez osoby posiadające zarost!',
      p_warn2: '•	Półmaski nie powinny być stosowane przez osoby posiadające nieregularne rysy twarzy uniemożliwiające zachowanie szczelności dopasowania!',
      p_warn3: '•	Należy zadbać o kompatybilność półmaski z innymi sprzętami ochrony układu oddechowego i okularami korekcyjnymi!',
      p_warn4: '•	Półmaska powinna być skompletowana z odpowiednim elementem oczyszczającym!',
      p_label1: 'Znakowanie półmaski powinno obejmować, co najmniej:',
      p_label2: '•	nazwę producenta lub znak towarowy identyfikujący producenta,',
      p_label3: '•	adres producenta,',
      p_label4: '•	rozmiar (jeśli półmaska występuje więcej niż w jednym rozmiarze),',
      p_label5: '•	numer lub rok wydania właściwej normy polskiej lub europejskiej,',
      p_label6: '•	znak CE i czterocyfrowy numer jednostki notyfikowanej,',
      p_label7: '•	numer serii lub datę produkcji.',
      p_label8: 'Filtry, których materiał filtrujący jest umieszczony wewnątrz obudowy (filtry kapsułowane) powinny wyróżniać się białą barwą obudowy lub białą etykietą oraz posiadać, co najmniej oznaczenie:',
      p_label9: '•	klasy i typu filtra,',
      p_label10: '•	informację, że filtr jest przeznaczony do sprzętu dwufiltrowego (jeśli dotyczy),',
      p_label11: '•	numer i rok wydania właściwej normy polskiej lub europejskiej,',
      p_label12: '•	przynajmniej rok dopuszczalnego okresu przechowywania,',
      p_label13: '•	nazwę producenta lub znak towarowy identyfikujący producenta,',
      p_label14: '•	adres producenta,',
      p_label15: '•	napis w oficjalnym języku kraju przeznaczenia: „Patrz informacje podane przez producenta” lub odpowiedni piktogram,',
      p_label16: '•	znak identyfikujący typ wyrobu,',
      p_label17: '•	znak CE i czterocyfrowy numer jednostki notyfikowanej,',
      p_label18: '•	numer serii lub datę produkcji.',
      p_label19: 'Filtry wykonane w całości z materiału filtracyjnego (bez obudowy) powinny mieć oznakowanie zawierające przynajmniej odpowiedni typ i klasę filtru oraz znak identyfikujący wyrób.',
      p_label20: 'Jeśli na filtrze nie umieszczono któregokolwiek z wyżej wymienionych oznaczeń powinno być ono uwzględnione na opakowaniu jednostkowym filtru. Bez konieczności otwierania opakowania powinny być widoczne informację o roku wygaśnięcia dopuszczalnego okresu przechowywania, znak identyfikujący wyrób i zalecane warunki przechowywania.',

      thp_p1: 'Filtrujący sprzęt z wymuszonym przepływem powietrza składa się z części twarzowej (np. hełm, kaptur), do której dostarczane jest powietrze poprzez element oczyszczający połączony za pomocą węża dmuchawą noszoną przez użytkownika.',
      thp_p2: 'Sprzęt z wymuszonym przepływem powietrza powinien spełniać wymagania zawarte w normie PN-EN 12941 „Sprzęt ochrony układu oddechowego. Oczyszczający sprzęt z wymuszonym przepływem powietrza wyposażony w hełm lub kaptur. Wymagania, badanie, znakowanie.”',
      thp_p3: 'Wyróżnia się trzy klasy sprzętu z wymuszonym przepływem powietrza zależne od wymaganej wartości przecieku wewnętrznego i wartości penetracji.',
      thp_p4: '•	Klasa THP1: 10%',
      thp_p5: '•	Klasa THP2: 2%',
      thp_p6: '•	Klasa THP3: 0,2%',
      thp_warn1: 'Sprzęt może być stosowany przez osoby posiadające zarost!',
      thp_warn2: 'Sprzęt może być stosowany przez osoby posiadające nieregularne rysy twarzy!',
      thp_warn3: 'Należy zadbać o kompatybilność sprzętu z innymi sprzętami ochrony układu oddechowego i okularami korekcyjnymi!',
      thp_warn4: 'Podzespoły i elementy mające wpływ na bezpieczeństwo powinny być tak oznakowane, aby były identyfikowalne.',
      thp_label1: 'Znakowanie kompletnego filtrującego sprzętu ochrony układu oddechowego z wymuszonym przepływem powietrza powinno zawierać nazwę klasy ochrony.',
      thp_label2: 'Znakowanie kaptura i hełmu powinno zawierać, co najmniej:',
      thp_label3: '•	nazwę producenta lub znak towarowy identyfikujący producenta,',
      thp_label4: '•	adres producenta',
      thp_label5: '•	znak identyfikujący typ,',
      thp_label6: '•	numer serii lub datę produkcji,',
      thp_label7: '•	numer i rok wydania właściwej normy polskiej lub europejskiej,',
      thp_label8: '•	znak CE i czterocyfrowy numer jednostki notyfikowanej,',
      thp_label9: '•	rozmiar, jeśli jest dostępnych więcej niż jeden,',
      thp_label10: 'Dmuchawa i obudowa akumulatora powinny zawierać, co najmniej:',
      thp_label11: '•	nazwę producenta lub znak towarowy identyfikujący producenta,',
      thp_label12: '•	adres producenta',
      thp_label13: '•	jeśli dotyczy, wskazanie, że sprzęt jest bezpieczny w użytkowaniu w atmosferze zagrożonej wybuchem i odniesienie do odpowiedniej normy polskiej lub europejskiej,',
      thp_label14: '•	numer serii lub datę produkcji,',
      thp_label15: '•	numer i rok wydania właściwej normy polskiej lub europejskiej,',
      thp_label16: '•	zdanie „Patrz informacje producenta w oficjalnym języku kraju przeznaczenia lub odpowiedni piktogram”.',
      thp_label17: 'Elementy oczyszczające powinny zawierać informacje zgodne z odpowiednimi normami, dla tego typu sprzętu.',
  },
    HistoryScreen: {
      title: 'Historia',
      type: 'TYP',
      date: 'DATA',
      time: 'CZAS UŻYCIA',
    },
    Web: {
      ciop: 'Centralny Instytut Ochrony Pracy - Państwowy Instytut Badawczy',
      appHeader: 'Aplikacja doboru sprzętu ochrony',
      appHeader2: 'układu oddechowego do zagrożeń biologicznych',
      homepage: 'Strona Główna',
      test: 'Rozpocznij test',
    },
    QuoteRotator: {
      0: 'Promuj zdrowe oddychanie',
      1: 'Zadbaj o bezpieczny oddech w środowisku pracy i życia',
      2: 'Zadbaj o bezpieczny oddech w pracy oraz podczas sportu i rekreacji',
      3: 'Wdrażaj nawyki zdrowego oddychania i chroń układ oddechowy przed zagrożeniami ( lub zanieczyszczonym powietrzem)',
      4: 'Pogłębiaj wiedzę o zdrowym i bezpiecznym oddychaniu',
      5: 'Włącz zdrowe nawyki oddechowe (lub zdrowe oddychanie)',
      6: 'Stosuj profilaktykę chorób oddechowych w pracy i w domu',
      7: 'Wybieraj bezpieczne oddychanie',
    },
    TimerScreen: {
      notification15_title: 'Zbliża się czas wymiany sprzętu',
      notification15_body: 'Za 15 minut powinieneś wymienić sprzęt!',
      notificationnow_title: 'Wymień sprzęt',
      notificationnow_body: 'Maksymalny czas użycia sprzętu właśnie minął!',

      finish: 'Zakończ timer',
      initial: 'Start',
      running: 'Czas do zmiany',
      paused: 'Pauza',
      expired: 'Wymień sprzęt!',
    },
  },
  en: {
    Navigation: {
      main: 'Homepage',
      params: 'Parameters',
      history: 'History',
      equipment: 'Equipment',
    },
    WelcomeScreen: {
      title: 'Homepaage',
      header: "Hello!",
      description: "This app helps you choose the right respiratory protective equipment. With the timer option, you will never again forget to replace it according to the manufacturer's recommendations.",
      howItWorks: "How it works?",
      howItWorksPoints: [
        "Fill out the form",
        "Check the recommendations and choose the respiratory protective equipment",
        "Use the timer to control the use time",
      ],
      start: "Let's start!",
      info: "This application was developed based on the results of a research task carried out within the fifth stage of the National Programme “Improvement of safety and working conditions” partly supported in 2021–2022 — within the scope of state services — by the Ministry of Family and Social Policy (task no. 3.SP.07 entitled “Mobile application supporting the selection of respiratory protective equipment for use against biological agents”). The Central Institute for Labour Protection – National Research Institute is the Programme’s main co-ordinator.",
      download: 'You can also download an application to your phone. The mobile version has an additional timer that will inform you when it is time to replace the RPE.',
      download_appstore: 'Download on the App Store',
      download_gplay: 'Download on the Google Play',
      ciop: 'Central Institute for Labour Protection – National Research Intitute',
    },
    FormScreen: {
      title: 'Fill out form',
      select: 'Select',
      select_item: '-- Remove selection --',
      less: 'I want to provide less data',
      more: 'I want to provide more data',
      show_recommended: 'Show recommended RPE',
      clear_form: 'Clear form',

      labels: {
        workplace: 'Work environment',
        environment: 'Place of work',
        oxygen: 'Oxygen content',
        gas_steam_presence: 'Presence of vapours and gases in the environment',
        temp: 'Temperature',
        humidity: 'Humidity',
        dust: 'Dust concentration',
        beard: 'I have facial hair',
        irregular_face: 'I have irregular facial features',
        glasses: 'I wear corrective glasses',
        micro_risk_group: 'Type of microorganisms',
        micro_risk_num: 'Amount of microorganisms',
        metabolites_air: 'Type of metabolites in the air',
        metabolites_dust: 'Type of metabolites in dust',
        metabolites_air_num: 'Number of metabolites in air',
        metabolites_dust_num: 'Number of metabolites in dust',
        cytotoxicity_dust: 'Dust cytotoxicity',
        biodiversity_air: 'Air biodiversity',
        biodiversity_dust: 'Dust biodiversity',
      },
      options: {
        agriculture: 'Agriculture',
        sewage: 'Sewage Treatment Plant',
        waste: 'Waste sorting plant',
        indoor: 'Indoor',
        outdoor: 'Outdoor',
        yes: 'Yes',
        no: 'No',
        nds1: '<1xTLV',
        nds2: '<1-4)xTLV',
        nds3: '<4-9)xTLV',
        nds4: '<9-20)xTLV',
        nds5: '>=20xTLV',
        microriskGroup1: 'Microorganisms from risk group 1',
        microriskGroup2: 'Microorganisms from risk group 2',
        microriskGroup3: 'Microorganisms from risk group 3',
        microriskGroup4: 'Microorganisms from risk group 4',
        microNum1: '< threshold',
        microNum2: '<1-4)x threshold',
        microNum3: '<4-9)x threshold',
        microNum4: '<9-20)x threshold',
        microNum5: '>=20x threshold',
        none: 'None',
        endotoxin: 'Endotoxin present',
        mold: 'Secondary metabolites of mould present',
        other: 'Other metabolites of microbial origin present',
        metabolitesAirNumLow: 'Bacterial endotoxin <200 ng/m3',
        metabolitesAirNumHigh: 'Bacterial endotoxin >200 ng/m3',
        metabolitesDustNumLow: 'Bacterial endotoxin < 0,155 nmol LPS mg-1',
        metabolitesDustNumHigh: 'Bacterial endotoxin > 0,155 nmol LPS mg-1',
        cytotoxicityLow: 'Low (no IC50 after 48h)',
        cytotoxicityHigh: 'High (determined IC50 after 48h)',
        biodiversityLow: 'Low',
        biodiversityHigh: 'High',
      },
    },
    ResultsScreen: {
      title: 'Recommended RPE',
      check: 'Check',
      no_protection_class: 'No equipment needed',
      insulating: 'Use isolation equipment',
      absorbing: 'Use absorbent equipment',
    },
    EquipmentDetailScreen: {
      title: 'RPE',
      description: 'Basic description',
      howToUse: 'How to use?',
      warningsAndLabeling: 'Warnings and labeling',
      warnings: 'Warnings',
      labeling: 'Labeling',
      runTimer: 'Run timer',

      FFP1: 'filtering facepiece respirator class FFP1',
      FFP2: 'filtering facepiece respirator class FFP2',
      FFP3: 'filtering facepiece respirator class FFP3',
      M1: 'mask with P1 class filters',
      M2: 'mask with P2 class filters',
      M3: 'mask with P3 class filters',
      P1: 'half mask with P1 class filters',
      P2: 'half mask with P2 class filters',
      P3: 'half mask with P3 class filters',
      THP1: 'powered air purifying respirator class THP1',
      THP2: 'powered air purifying respirator class THP2',
      THP3: 'powered air purifying respirator class THP3',

      fpp_p1: 'A respirator consists of a combination of non-woven filter fabrics joined at the periphery. It covers the user\'s nose, mouth and chin. Often, an exhalation valve is fitted to improve breathing conditions. Filtering half-masks are equipped with a headpiece, which is an arrangement of haberdashery, rubber or latex bands, fasteners or ear cuffs. In respirators without an exhalation valve, the air flow during the inhalation and exhalation phases is through the filtering material, and in respirators with an exhalation valve through the filtering material and the exhalation valve.',
      fpp_p2: 'Filtering respirators shall meet the requirements of EN 149 Respiratory protective equipment. Filtering half-masks for protection against particles. Requirements, testing, marking.',
      fpp_p3: 'Filtering respirators shall be classified (and marked) in relation to their filtration efficiency and maximum permissible total internal leakage.',
      fpp_p4: 'Maximum penetration of paraffin oil mist:',
      fpp_p5: 'Maximum penetration of sodium chloride aerosol:',
      fpp_p6: 'Maximum total internal leakage:',
      fpp_p7: '* At least 46 out of 50 individual CPW results / at least 8 out of 10 average CPW for each participant should not be greater than the specified value',
      fpp_warn1: '- Filtering respirators should not be used by people with facial hair!',
      fpp_warn2: '- Filtering half masks should not be used by people with irregular facial features that make a tight fit impossible!',
      fpp_warn3: '- Ensure that the filtering respirator is compatible with other personal protective equipment and corrective eyewear!',
      fpp_label1: 'Labeling of filtering respirators should include, at a minimum:',
      fpp_label2: '- manufacturer\'s name or trademark identifying the manufacturer,',
      fpp_label3: '- address of the manufacturer,',
      fpp_label4: '- marking identifying the type of respirator,',
      fpp_label5: '- symbol for the applicable protection class,',
      fpp_label6: '- serial number or manufacturing date,',
      fpp_label7: '- number and year of issue of the relevant Polish or European standard,',
      fpp_label8: '- CE mark and 4 digit number of the notified body,',
      fpp_label9: '- symbol NR if the respirator is intended for single-shift use or symbol R if the respirator is intended for multiple-shift use,',
      fpp_label10: '- respirators that have passed the dolomite dust clogging test shall be marked with the letter D,',

      m_p1: 'A mask is a facepiece that covers the eyes, nose, mouth, and chin. It has an exhalation valve or valves to facilitate breathing. It may be used with 1 or 2 cleansing elements (e.g., a filter, a canister, or a filter-absorber) or a breathing apparatus.',
      m_p2: 'Masks shall meet the requirements of EN 136 \'Respiratory protective equipment. Masks. Requirements, testing, marking\'.',
      m_p3: 'There are three classes of masks:',
      m_p4: '- Class 1: for light use,',
      m_p5: '- Class 2: for general use,',
      m_p6: '- Class 3: for special use.',
      m_p7: 'Masks should be completed with an appropriate purifying element (filter or filter set).',
      m_p8: 'Filters consist of a system of non-woven filtering fibres joined together at the periphery (flat filters); loose fibres or a system of non-woven filtering fibres enclosed in a casing allowing air to flow freely (encapsulated filters) or pleated non-woven fabric with an outlet port and a thread or bayonet connector (cascaded filters with a connector). Their principle of operation is to capture the dirt particles from the flow of air through the filtering material. When combined with an appropriate part, filters constitute equipment with varying degrees of efficiency.',
      m_p9: 'Filters should meet requirements included in EN 143 "Respiratory protective equipment. Filters. Requirements, testing, marking',
      m_p10: 'Filter classification relates directly to their filtration efficiency against two test aerosols: paraffin oil mist and sodium chloride aerosol',
      m_p11: 'Maximum penetration of paraffin oil mist:',
      m_p12: 'Maximum penetration of sodium chloride aerosol:',
      m_warn1: 'Masks should not be used by people with facial hair!',
      m_warn2: 'Masks should not be used by individuals with irregular facial features that prevent a tight fit!',
      m_warn3: 'Ensure your mask is compatible with other PPE and corrective eyewear!',
      m_warn4: 'The mask should be completed with an appropriate cleansing element!',
      m_label1: 'Mask labeling should include, at a minimum:',
      m_label2: '- manufacturer\'s name or trademark identifying the manufacturer,',
      m_label3: '- address of the manufacturer,',
      m_label4: '- mask class preceded by the letters CL,',
      m_label5: '- number or year of the relevant Polish or European standard,',
      m_label6: '- CE mark and four digit number of the notified body,',
      m_label7: '- serial number or manufacturing date.',
      m_label8: 'All parts of the same model should be marked identically for the type',
      m_label9: 'Filters with filter material inside the housing (encapsulated filters) shall be distinguished by a white housing color or a white label and shall be marked at least:',
      m_label10: '- filter class and type,',
      m_label11: '- information that the filter is intended for two-filter equipment (if applicable),',
      m_label12: '- number and year of issue of the relevant Polish or European standard,',
      m_label13: '- at least one year\'s shelf life,',
      m_label14: '- name of the manufacturer or trade mark identifying the manufacturer,',
      m_label15: '- address of the manufacturer,',
      m_label16: '- an inscription in the official language of the country of destination: See information given by the manufacturer\' or an appropriate pictogram,',
      m_label17: '- product type identification mark,',
      m_label18: '- CE marking and 4 digit number of notified body,',
      m_label19: '- serial number or manufacturing date',
      m_label20: 'Filters made entirely of filter material (without housing) shall be labeled with at least the appropriate filter type and class and a mark identifying the product.',
      m_label21: 'If the filter does not have any of the above markings on it, they should be included on the filter unit packaging. Without opening the package, information on the year of expiration of the allowable storage period, the product identification mark and recommended storage conditions should be visible.',

      p_p1: 'A respirator is a facepiece that covers the nose, mouth, and chin. It has an exhalation valve or valves to facilitate breathing. This equipment may be adapted to be completed with 1 or 2 purifying elements (e.g., a filter, a canister, or a filter-absorber) mounted on connectors with an inhalation valve.',
      p_p2: 'Half masks shall meet the requirements of EN 140 \'Respiratory protective equipment. Half masks and quarter masks. Requirements, testing, marking.\'',
      p_p3: 'Half-masks shall be completed with an appropriate purifying element (filter or set of filters).',
      p_p4: 'Filters consist of a system of filtering fibres connected together at the periphery (flat filters); loose fibres or a system of filtering fibres, enclosed in a casing allowing air to flow freely (encapsulated filters) or pleated fibres with an outlet hole and a thread or bayonet connector (cascaded filters with a connector). Their principle of operation is to capture the dirt particles from the flow of air through the filtering material. When combined with a filter element, the filters can be used as equipment with varying degrees of efficiency',
      p_p5: 'Filters should meet the requirements of EN 143 "Respiratory protective equipment. Filters. Requirements, testing, marking',
      p_p6: 'Filter classification relates directly to their filtration efficiency against two test aerosols: paraffin oil mist and sodium chloride aerosol',
      p_p7: 'Maximum penetration of paraffin oil mist:',
      p_p8: 'Maximum penetration of sodium chloride aerosol:',
      p_warn1: '- respirators should not be used by people with facial hair!',
      p_warn2: '- Half mask respirators should not be used by people with irregular facial features that make a tight fit impossible!',
      p_warn3: '- Make sure the respirator is compatible with other PPE and corrective glasses!',
      p_warn4: '- The respirator should be completed with a suitable cleansing element!',
      p_label1: 'The marking of the respirator should include, at least:',
      p_label2: '- manufacturer\'s name or trademark identifying the manufacturer,',
      p_label3: '- the manufacturer\'s address,',
      p_label4: '- size (if the respirator comes in more than one size),',
      p_label5: '- number or year of the relevant Polish or European standard,',
      p_label6: '- CE mark and 4 digit number of the notified body,',
      p_label7: '- serial number or manufacturing date.',
      p_label8: 'Filters with filter material inside the housing (encapsulated filters) shall be distinguished by a white housing color or a white label and shall bear at least the following indication:',
      p_label9: '- filter class and type,',
      p_label10: '- information that the filter is intended for two-filter equipment (if applicable),',
      p_label11: '- number and year of the relevant Polish or European standard,',
      p_label12: '- at least one year\'s shelf life,',
      p_label13: '- name of the manufacturer or trade mark identifying the manufacturer,',
      p_label14: '- address of the manufacturer,',
      p_label15: '- an inscription in the official language of the country of destination: See information given by the manufacturer or an appropriate pictogram,',
      p_label16: '- mark identifying the product type,',
      p_label17: '- CE marking and 4 digit number of notified body,',
      p_label18: '- serial number or manufacturing date',
      p_label19: 'Filters made entirely of filter material (without housing) shall be marked with at least the appropriate filter type and class and a mark identifying the product.',
      p_label20: 'If any of the above markings are not indicated on the filter, they should be included on the filter unit packaging. Without opening the package, information on the year of expiration of the allowable storage period, the product identification mark and recommended storage conditions should be visible.',

      thp_p1: 'Powered air purifying respirator consists of a facepiece (e.g., helmet, hood) to which air is supplied through a purifying element connected by a hose to a blower worn by the wearer.',
      thp_p2: 'Powered air purifying respirator shall meet the requirements of EN 12941 "Respiratory protective equipment. Powered air purifying respirator equipment fitted with a helmet or hood. Requirements, testing, marking."',
      thp_p3: 'There are three classes of positive airflow equipment depending on the required internal leakage value and penetration value.',
      thp_p4: '- THP1 class: 10%',
      thp_p5: '- THP2 class: 2%',
      thp_p6: '- THP3 class: 0.2%',
      thp_warn1: 'Equipment can be used by people with facial hair!',
      thp_warn2: 'The equipment can be used by people with irregular facial features!',
      thp_warn3: 'Ensure equipment is compatible with other PPE and corrective eyewear!',
      thp_warn4: 'Components and parts affecting safety should be marked so that they are identifiable.',
      thp_label1: 'The marking of complete powered air purifying respirator should include the name of the protection class.',
      thp_label2: 'Hood and helmet labeling shall include, at a minimum:',
      thp_label3: '- manufacturer\'s name or trademark identifying the manufacturer,',
      thp_label4: '- the manufacturer\'s address',
      thp_label5: '- type identification mark,',
      thp_label6: '- serial number or manufacturing date,',
      thp_label7: '- number and year of the relevant Polish or European standard,',
      thp_label8: '- CE mark and 4 digit number of notified body,',
      thp_label9: '- size if more than one,',
      thp_label10: 'The blower and battery case shall contain, at a minimum:',
      thp_label11: '- manufacturer\'s name or trademark identifying the manufacturer,',
      thp_label12: '- address of the manufacturer',
      thp_label13: '- where applicable, an indication that the equipment is safe for use in a potentially explosive atmosphere and reference to the relevant Polish or European standard,',
      thp_label14: '- serial number or date of manufacture,',
      thp_label15: '- number and year of the relevant Polish or European standard,',
      thp_label16: '- the sentence "Refer to the manufacturer\'s information in the official language of the country of destination or the applicable pictogram."',
      thp_label17: 'Cleaning elements should contain information according to the relevant standards, for this type of equipment.',
    },
    HistoryScreen: {
      title: 'History',
      type: 'TYPE',
      date: 'DATE',
      time: 'TIME OF USE',
    },
    Web: {
      ciop: 'Central Institute for Labour Protection – National Research Intitute',
      appHeader: 'Application supporting the selection',
      appHeader2: 'of respiratory protected equipment against biological hazards',
      homepage: 'Homepage',
      test: 'Start test',
    },
    QuoteRotator: {
      0: 'Promote healthy breathing',
      1: 'Ensure safe breathing in your work and living environment',
      2: 'Take care of safe breathing at work and during sport and recreation',
      3: 'Implement healthy breathing habits and protect your respiratory system from hazards (or air pollution)',
      4: 'Increase knowledge about healthy and safe breathing',
      5: 'Incorporate healthy breathing habits (or healthy breathing)',
      6: 'Apply respiratory disease prevention at work and at home',
      7: 'Choose safe breathing',
    },
    TimerScreen: {
      notification15_title: 'Equipment replacement time is approaching',
      notification15_body: 'In 15 minutes you should be replacing equipment!',
      notificationnow_title: 'Replace the equipment',
      notificationnow_body: 'The maximum equipment usage time has just expired!',

      finish: 'End timer',
      initial: 'Start',
      running: 'Time to change',
      paused: 'Pause',
      expired: 'Replace the equipment!',
    },
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetectorPlugin)
  .init({
    resources,
    compatibilityJSON: 'v3',
    fallbackLng: "pl",
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
