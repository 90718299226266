import React, {useEffect, useState} from "react";
import {Box, FlatList, HStack, Icon, Text, View, VStack} from "native-base";
import {renderTime} from "../../helpers/renderTime";
import {Feather} from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {STORAGE} from "../../consts";
import {useFocusEffect} from "@react-navigation/native";
import {useTranslation} from "react-i18next";

export type HistoryItem = {
  equipment: string,
  date: Date,
  time: number,
}

export default function HistoryScreen() {
  const [items, setItems] = useState<HistoryItem[]>([]);
  const {t} = useTranslation('HistoryScreen');

  useFocusEffect(() => {
    AsyncStorage.getItem(STORAGE.HISTORY)
      .then((result) => {
        if (result != null) {
          let data = JSON.parse(result);
          setItems(data);
        }
      });
  });

  const renderItem = ({index, item}: { index: number, item: HistoryItem }) => {
    const renderDate = () => {
      const date = new Date(item.date);
      const month = date.setMonth(date.getMonth() + 1);

      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    };

    return <HStack
      key={index}
      alignItems='center'
      paddingX={2}
      bg={index % 2 === 0 ? 'white' : 'gray.50'}
      borderBottomWidth={1}
      borderBottomColor={'gray.200'}
    >
      <Box flex={4} paddingY={3} paddingX={2} borderRightWidth={1} borderRightColor='gray.200'>
        <Text>{item.equipment}</Text>
      </Box>
      <VStack flex={2} paddingX={2} alignItems='center'>
        <Text>{renderDate()}</Text>
        <HStack alignItems='center'>
          <Icon as={Feather} name='clock' color='gray.400' size={4} marginX={1} />
          <Text>{renderTime(item.time)}</Text>
        </HStack>
      </VStack>
    </HStack>
  }

  return <Box flex={1} bg='white'>
    <HStack paddingY={2} paddingX={4} bg='gray.50'>
      <Text fontWeight='normal' flex={4}>{t('type')}</Text>
      <VStack paddingX={2} alignItems='center'>
        <Text fontWeight='normal'>{t('date')}</Text>
        <Text fontWeight='normal'>{t('time')}</Text>
      </VStack>
    </HStack>

    <FlatList
      data={items}
      renderItem={renderItem}
      keyExtractor={(item, index) => index.toString()}
    />
  </Box>;
}
