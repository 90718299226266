import {Box, Icon, Text, View} from "native-base";
import {Foundation} from "@expo/vector-icons";
import {Animated, Easing, LayoutChangeEvent} from "react-native";
import React, {useEffect, useMemo, useState} from "react";
import {States} from "../../../enums/StatesEnum";
import {renderTime} from "../../../helpers/renderTime";
import {useTranslation} from "react-i18next";
import {State} from "react-native-gesture-handler";
import AnimatedInterpolation = Animated.AnimatedInterpolation;

export default function Clock(
  {timer, timeLeft}: {timer: {state: States}, timeLeft: number}
) {
  const [circleSize, setCircleSize] = useState(0);
  const {t} = useTranslation('TimerScreen');

  const onLayoutHandler = (e: LayoutChangeEvent) => {
    setCircleSize(e.nativeEvent.layout.width);
  };

  /** Animation **/
  const animationValue = useMemo(() => new Animated.Value(0), [timer]);
  const animation = Animated.loop(
    Animated.timing(animationValue, {
      toValue: 4,
      duration: 15000,
      useNativeDriver: false,
      easing: Easing.linear,
    })
  );
  const bgAnimation = animationValue.interpolate({
    inputRange: [0, 1, 2, 3, 4],
    outputRange: ['#1DF24C', '#1DF2B2', '#1DE5F2', '#1DF2B2', '#1DF24C'],
  });

  const animationPauseValue = useMemo(() => new Animated.Value(0), [timer]);
  const animationPause = Animated.loop(
    Animated.timing(animationPauseValue, {
      toValue: 2,
      duration: 3000,
      useNativeDriver: false,
      easing: Easing.linear,
    })
  );
  const pauseColor = animationPauseValue.interpolate({
    inputRange: [0, 1, 2],
    outputRange: ['#E7E7E7', '#656565', '#E7E7E7'],
  });

  useEffect(() => {
    if (timer.state === States.RUNNING) {
      animation.start();
    } else {
      animation.stop();
    }

    if (timer.state === States.PAUSED) {
      animationPause.start();
    } else {
      animationPause.stop();
    }
  }, [timer]);

  let textColor: string|AnimatedInterpolation = '#006849';
  let bgColor: string|AnimatedInterpolation = bgAnimation;

  if (timer.state === States.PAUSED) {
    textColor = pauseColor;
  } else if (timer.state === States.EXPIRED) {
    textColor = '#EA3131';
    bgColor = '#EA3131';
  }

  return <View
    onLayout={onLayoutHandler}
  >
    <Animated.View
      style={{
        backgroundColor: bgColor,
        marginVertical: 40,
        padding: 10,
        width: circleSize,
        height: circleSize,
        borderRadius: circleSize*2,
      }}
    >
      <Box flex={1} bg='white' borderRadius={circleSize * 2} justifyContent='center' alignItems='center' shadow={timer.state === States.EXPIRED ? 5 : 0}>
        <Box flexDirection='column' justifyContent='flex-end' alignItems='center' flex={1}>
          <Box height={6}>
            {timer.state === States.PAUSED &&
                <Icon as={Foundation} name='pause' size='sm' color='primary.500' textAlign='center' />
            }
          </Box>

          <Text fontSize='xl' color={textColor} fontWeight='semibold'>{
            {
              [States.INITIAL]: t('initial'),
              [States.RUNNING]: t('running'),
              [States.PAUSED]: t('paused'),
              [States.EXPIRED]: t('expired'),
            }[timer.state]
          }</Text>
        </Box>
        <Box flex={1} justifyContent='center' alignItems='center'>
          <Animated.Text
            style={{
              color: textColor,
              fontWeight: 'bold',
              fontSize: 50,
            }}
          >
            {renderTime(timeLeft)}
          </Animated.Text>
        </Box>
        <Box flex={1} />
      </Box>
    </Animated.View>
  </View>
}
