export const STORAGE = {
  LANGUAGE: 'settings.lang',
  FORM: 'form',
  PROTECTION_CLASS: 'protection',
  MCU: 'mcu',

  SELECTED_EQUIPMENT: 'selectedEquipment',
  TIMER: 'timer',
  HISTORY: 'history',
};

let API_URL = (__DEV__)
  ? 'https://ciop-stats.devmouse.pl/api/add'
  : 'https://t4m.ciop.pl/api/add';

export const GPLAY_URL = 'https://play.google.com/store/apps/details?id=com.ciop.souo';

export {API_URL};
