import * as Linking from 'expo-linking';
import {LinkingOptions} from "@react-navigation/native/lib/typescript/src/types";
import {RootStackParamList} from "../Types";

export default {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Timer: 'timer',
      Root: {
        screens: {
          WelcomeStack: {
            screens: {
              WelcomeScreen: '/',
            },
          },
          FormStack: {
            screens: {
              Form: 'form',
              Results: 'results',
              EquipmentDetail: 'equipment',
            },
          },
          HistoryStack: {
            screens: {
              History: 'history',
            },
          },
        },
      },
    },
  },
} as LinkingOptions<RootStackParamList>;
