import {NavigationContainer, useNavigation} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import BottomTabNavigator from './BottomTabNavigator';
import {RootStackParamList} from "../Types";
import TimerScreen from "../screens/Timer/TimerScreen";
import {Platform} from "react-native";
import LinkingConfiguration from "./LinkingConfiguration";
import WebStackNavigator from "./WebStackNavigator";
import {useTimer} from "../providers/TimerProvider";

export default function Navigation() {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

const Navigator = (Platform.OS === 'web') ? WebStackNavigator : BottomTabNavigator;

const Stack = createStackNavigator<RootStackParamList>();
function RootNavigator() {
  const {timerShown} = useTimer();

  return (
    <Stack.Navigator screenOptions={{
      headerShown: false,
      presentation: 'modal',
    }}>
      {(timerShown && Platform.OS !== 'web')
        ? <Stack.Screen name="Timer" component={TimerScreen} />
        : <Stack.Screen name="Root" component={Navigator} />}
    </Stack.Navigator>
  );
}
