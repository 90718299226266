import {BottomTabParamList} from "../Types";
import WelcomeNavigator from "../screens/Welcome";
import FormNavigator from "../screens/Form";
import HistoryNavigator from "../screens/History";
import * as React from "react";
import {createStackNavigator} from "@react-navigation/stack";
import EquipmentDetailScreen from "../screens/Form/EquipmentDetailScreen";
import {useEquipment} from "../providers/EquipmentProvider";

const WebStack = createStackNavigator<BottomTabParamList>();
export default function WebStackNavigator() {
  const {equipment} = useEquipment();

  return (
    <WebStack.Navigator
      initialRouteName="WelcomeStack"
      screenOptions={{
        headerShown: false,
      }}
    >
      <WebStack.Screen
        name="WelcomeStack"
        component={WelcomeNavigator}
      />
      <WebStack.Screen
        name="FormStack"
        component={FormNavigator}
      />
      <WebStack.Screen
        name="HistoryStack"
        component={HistoryNavigator}
      />
      <WebStack.Screen
          name="EquipmentDetail"
          component={EquipmentDetailScreen}
      />
    </WebStack.Navigator>
  );
}
