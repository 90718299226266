import {Collapse} from "native-base";
import React, {useContext} from "react";
import {IAccordionDetails, IAccordionItemContextProps} from "./types";
import {AccordionItemContext} from "./Context";

export default function AccordionDetails(props: IAccordionDetails) {
  const { isOpen }: IAccordionItemContextProps = useContext(AccordionItemContext);

  return <Collapse
    isOpen={isOpen}
  >
    {props.children}
  </Collapse>
}
