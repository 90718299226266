import AccordionBase from "./Accordion";
import AccordionItem from "./AccordionItem";
import AccordionDetails from "./AccordionDetails";
import AccordionSummary from "./AccordionSummary";

const AccordionTemp: any = AccordionBase;
AccordionTemp.Item = AccordionItem;
AccordionTemp.Summary = AccordionSummary;
AccordionTemp.Details = AccordionDetails;

const Accordion = AccordionTemp;

export { Accordion };
export { AccordionContext } from './Context';
