import React, {createContext, useContext, useEffect, useState} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {STORAGE} from "../consts";
import ITimer from "../models/ITimer";

interface TimerContextInterface {
  timerShown: boolean,
  setTimerShown: (state: boolean) => void,
  setTimer: (timer: ITimer) => void,
  timer: ITimer|false,
  stopTimer: () => void,
}


const TimerContext = createContext<TimerContextInterface | null>({
  timerShown: false,
  setTimerShown: () => {},
  setTimer: () => {},
  timer: false,
  stopTimer: () => {},
});

export const useTimer = () => {
  const context = useContext(TimerContext);

  if (!context) {
    throw new Error('Error');
  }

  return context;
};

export const TimerProvider: React.FC = (props) => {
  const [timerShown, setTimerShown] = useState<boolean>(false);
  const [timerState, setTimerState] = useState<ITimer|false>(false);

  useEffect(() => {
    loadTimerFromStorage();
  }, []);

  const loadTimerFromStorage = async () => {
    let string = await AsyncStorage.getItem(STORAGE.TIMER);

    if (string) {
      let timer: ITimer = JSON.parse(string);

      if (timer) {
        setTimerShown(true);
        setTimerState(timer);
      }
    }
  }

  const setTimer = (timer: ITimer) => {
    AsyncStorage.setItem(STORAGE.TIMER, JSON.stringify(timer));
    setTimerState(timer);
    setTimerShown(true);
  }

  const stopTimer = async () => {
    await AsyncStorage.removeItem(STORAGE.TIMER);

    setTimerState(false);
    setTimerShown(false);
  }

  return (
    <TimerContext.Provider value={{
      timerShown,
      setTimerShown,
      setTimer,
      timer: timerState,
      stopTimer,
    }}>
      {props.children}
    </TimerContext.Provider>
  );
};
