import {Box, Text, Image, HStack, Link, VStack, useMediaQuery, Stack} from "native-base";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigation} from "@react-navigation/native";
import {BottomTabParamList} from "../../Types";
import {NavigationProp} from "@react-navigation/core/src/types";
import {GPLAY_URL} from "../../consts";
import {Image as ReactImage, Platform} from "react-native";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const { navigate } = useNavigation<NavigationProp<BottomTabParamList>>();
  const [isSmallScreen] = useMediaQuery({
    minWidth: 0,
    maxWidth: 800
  });

  const openAppStore = () => {

  }

  const openGPlay = () => {
    if (Platform.OS === 'web') {
      window.open(GPLAY_URL, '_blank');
    }
  }

  return <Stack direction={isSmallScreen ? 'column' : 'row'} space={4} paddingX={4} paddingY={10} bg='footer' marginTop='auto'>
    <VStack space={5} flex={1} paddingRight={4}>
      <Stack direction={isSmallScreen ? 'column' : 'row'} justifyContent='space-between'>
        <Box marginBottom={isSmallScreen ? 4 : 0}>
          <Link href='/'><ReactImage source={require('../../assets/logo_dark.png')} style={{width:198, height:32}} /></Link>
        </Box>

        {i18n.language === 'en'
          ? <HStack space={4}>
            <Link onPress={openAppStore}>
              <Image width={102} height={34} source={require('../../assets/download_appstore_en.svg')}
                     alt={t('download_appstore')} />
            </Link>
            <Link onPress={openGPlay}>
              <Image width={116} height={34} source={require('../../assets/download_gplay_en.png')}
                     alt={t('download_gplay')} />
            </Link>
          </HStack>
          : <HStack space={4}>
            <Link onPress={openAppStore}>
              <Image width={102} height={34} source={require('../../assets/download_appstore.svg')}
                     alt={t('download_appstore')} />
            </Link>
            <Link onPress={openGPlay}>
              <Image width={116} height={34} source={require('../../assets/download_gplay.png')}
                     alt={t('download_gplay')} />
            </Link>
          </HStack>
        }
      </Stack>
      <HStack space={3}>
        <Link
          _text={{
            color: 'coolGray.100',
            textDecoration: 'none',
          }}
          _hover={{
            _text: {
              color: 'primary.300',
            }
          }}
          onPress={() => navigate('WelcomeStack', {screen: 'Welcome'})}
        >{t('Web:homepage')}</Link>
        <Text color='coolGray.100'>•</Text>
        <Link
          _text={{
            color: 'coolGray.100',
            textDecoration: 'none'
          }}
          _hover={{
            _text: {
              color: 'primary.300',
            }
          }}
          onPress={() => navigate('FormStack', {screen: 'Form'})}
        >{t('Web:test')}</Link>
      </HStack>
    </VStack>

    <Box flex={1} paddingLeft={isSmallScreen ? 0 : 4} paddingTop={isSmallScreen ? 4 : 0}>
      <Text color='coolGray.100' fontSize='2xs'>{t('WelcomeScreen:info')}</Text>
    </Box>
  </Stack>
}
