import {Heading} from "native-base";
import React from "react";
import {useEquipment} from "../../../providers/EquipmentProvider";

export default function Header() {
  const {equipment} = useEquipment();

  return <Heading color='white' textAlign='center' style={{
    textShadowOffset: {width: 0, height: 0},
    textShadowRadius: 6,
    textShadowColor: 'rgba(0, 0, 0, 0.3)',
  }}>
    {equipment && (equipment.name ?? '')}
  </Heading>
}
