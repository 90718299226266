import React, {createContext, useContext, useEffect, useState} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {STORAGE} from "../consts";
import {EquipmentTypes, IEquipmentType} from "../equipment";

interface EquipmentProviderInterface {
  equipment: IEquipmentType|undefined,
  setEquipmentId: (equipmentId: string) => void,
}


const EquipmentContext = createContext<EquipmentProviderInterface | null>({
  equipment: undefined,
  setEquipmentId: () => {},
});

export const useEquipment = () => {
  const context = useContext(EquipmentContext);

  if (!context) {
    throw new Error('Error');
  }

  return context;
};

export const EquipmentProvider: React.FC = (props) => {
  const [equipment, setEquipment] = useState<IEquipmentType>();

  useEffect(() => {
    loadEquipmentFromStorage();
  }, []);

  const loadEquipmentFromStorage = async () => {
    let id = await AsyncStorage.getItem(STORAGE.SELECTED_EQUIPMENT);

    if (id) {
      if (EquipmentTypes[id]) {
        setEquipment(EquipmentTypes[id]);
      }
    }
  }

  const setEquipmentId = (id: string) => {
    AsyncStorage.setItem(STORAGE.SELECTED_EQUIPMENT, id);

    if (EquipmentTypes[id]) {
      setEquipment(EquipmentTypes[id]);
    }
  }

  return (
    <EquipmentContext.Provider value={{
      equipment,
      setEquipmentId
    }}>
      {props.children}
    </EquipmentContext.Provider>
  );
};
