import {useTranslation} from "react-i18next";
import GradientButton from "../../../components/GradientButton";
import {Button, HStack, useMediaQuery} from "native-base";
import React from "react";
import {Platform} from "react-native";

interface ILanguage {
  name: string;
  label: string;
}

export default function LanguagePicker() {
  const {i18n} = useTranslation(); //i18n instance
  const [isSmallScreen] = useMediaQuery({
    minWidth: 0,
    maxWidth: 800
  });

  const languages = [
    {name: "pl", label: "Polski"},
    {name: "en", label: "English"},
  ];

  const renderLanguage = (language: ILanguage) => {
    const onPressLanguage = async () => {
      await i18n.changeLanguage(language.name);
    }

    if (i18n.language == language.name) {
      return <GradientButton key={language.name}>{language.label}</GradientButton>
    }

    return <Button key={language.name} paddingLeft={6} paddingRight={6} variant='outline' onPress={onPressLanguage}>
      {language.label}
    </Button>;
  };


  return <HStack justifyContent={Platform.OS === 'web' && isSmallScreen ? 'flex-start' : 'flex-end'}
                 marginTop={Platform.OS === 'web' && isSmallScreen ? 2 : 0} space={2}>
    {languages.map(renderLanguage)}
  </HStack>;
}
