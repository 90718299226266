import React, {useEffect, useState} from "react";
import {
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  Collapse,
  FormControl,
  HStack,
  Stack,
  Switch,
  Text,
  VStack
} from "native-base";
import GradientButton from "../../components/GradientButton";
import {useTranslation} from "react-i18next";
import {useNavigation} from "@react-navigation/native";
import {NavigationProp} from "@react-navigation/core/src/types";
import {FormParamList} from "../../Types";
import EquipmentForm, {Field, SelectField} from "../../forms/EquipmentForm";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {STORAGE} from "../../consts";
import AppLoading from "expo-app-loading";
import ContentWrapper from "../../components/ContentWrapper";
import {MCU} from "../../forms/consts";
import Select from "../../components";

export default function FormScreen() {
  const form = EquipmentForm;
  const fields = Object.entries(form);
  const simpleFields = fields.filter(([, field]) => !field.advance);
  const advancedFields = fields.filter(([, field]) => field.advance);

  const {t} = useTranslation('FormScreen');
  const {navigate} = useNavigation<NavigationProp<FormParamList>>();
  const [advanceMode, setAdvanceMode] = useState(false);
  const [formData, setFormData] = useState<any>(fields.map(() => null));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    AsyncStorage.getItem(STORAGE.FORM)
      .then((form) => {
        if (form) {
          setFormData(JSON.parse(form));
        }
        setIsLoading(false);
      });
  }, []);

  const onSubmit = () => {
    navigate('Results');
  };

  const onAdvanceModePressHandler = () => {
    setAdvanceMode(mode => !mode);
  }

  const clearForm = () => {
    setFormData({
      workplace: null,
      environment: null,
      oxygen: null,
      gas_steam_presence: null,
      temp: null,
      humidity: null,
      dust: null,
      beard: null,
      irregular_face: null,
      glasses: null,
      micro_risk_group: null,
      micro_risk_num: null,
      metabolites_air: null,
      metabolites_dust: null,
      metabolites_air_num: null,
      metabolites_dust_num: null,
      cytotoxicity_dust: null,
      biodiversity_air: null,
      biodiversity_dust: null,
    });
  }

  useEffect(() => {
    let protectionClass = Math.max(...fields.map(([key, field]) => {
      return field.getProtectionClass(parseInt(formData[key] ?? -1), formData) ?? -1;
    }));

    if (protectionClass === -1) {
      protectionClass = 0;
    }

    let mcu =
      Math.min(...fields.map(([key, field]) => {
        return field.getMcu(parseInt(formData[key] ?? -1), formData) ?? 1;
      }))
      * MCU
      * 60
      * 60;

    // Save form
    AsyncStorage.setItem(
      STORAGE.FORM,
      JSON.stringify(formData)
    );

    // Save calculated protection class
    AsyncStorage.setItem(STORAGE.PROTECTION_CLASS, protectionClass.toString());
    AsyncStorage.setItem(STORAGE.MCU, mcu.toString());
  }, [formData])

  const renderField = (key: string, field: Field) => {
    if (field instanceof SelectField) {
      return <FormControl key={key}>
        <FormControl.Label>{t('labels.' + key)}</FormControl.Label>

        <Select
          accessibilityLabel={key}
          placeholder={t("select")}
          onValueChange={(value) => {
            setFormData({...formData, [key]: value});
          }}
          selectedValue={formData[key] ?? -1}
        >
          {Object.entries(field.options).map(
            ([optionKey, optionValue]) =>
              <Select.Item key={optionKey} label={
                t('options.' + optionValue.toString(), optionValue.toString())
              } value={optionKey}/>
          )}
        </Select>
      </FormControl>
    }

    return <HStack justifyContent='space-between' alignItems='center' key={key}>
      <Text fontWeight='semibold'>{t('labels.' + key)}</Text>
      <Switch
        name={key}
        onToggle={() => setFormData({...formData, [key]: !formData[key]})}
        isChecked={formData[key] ?? false}
      />
    </HStack>;
  }

  if (isLoading) {
    return <AppLoading/>;
  }

  return (
    <ContentWrapper>
      <VStack space={4} my={2}>
        {simpleFields.map(([key, field]) => renderField(key, field))}

        <Button
          size='lg'
          variant='outline'
          onPress={onAdvanceModePressHandler}
          rightIcon={advanceMode ? <ChevronUpIcon color='primary.500'/> : <ChevronDownIcon color='primary.500'/>}
        >
          {advanceMode ? t('less') : t('more')}
        </Button>

        <Collapse isOpen={advanceMode}>
          <VStack space={4}>
            {advancedFields.map(([key, field]) => renderField(key, field))}
          </VStack>
        </Collapse>

        <VStack>
          <Button
            size='lg'
            variant='outline'
            onPress={clearForm}
          >
            {t('clear_form')}
          </Button>

          {/* @ts-ignore */}
          <GradientButton _gradientStack={{flex: 1}} size='lg' onPress={onSubmit}>
            {t('show_recommended')}
          </GradientButton>
        </VStack>
      </VStack>
    </ContentWrapper>
  );
}
