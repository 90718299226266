import {ImageURISource} from "react-native";
import React from "react";
import THP from "./THP";
import {ProtectionClasses} from "../forms/EquipmentForm";
import {TFunction} from "i18next";
import {Box, Text, VStack} from "native-base";

class THP3 extends THP {
  readonly name: string = 'sprzęt z wymuszonym przepływem klasy THP3';
  readonly image: ImageURISource = require('../assets/equipment/TH.jpg');
  readonly protectionClass: ProtectionClasses = ProtectionClasses.P3;

  readonly code: string = 'THP3';
  readonly index: string = 'THP3';

  description = (t: TFunction): React.ReactNode => <VStack space={2}>
    <Text>{t('thp_p1')}</Text>
    <Text>{t('thp_p2')}</Text>
    <Text>{t('thp_p3')}</Text>
    <Box>
      <Text>{t('thp_p6')}</Text>
    </Box>
  </VStack>;
}

export default new THP3();
