import * as Consts from './consts';
import {HUMIDITY_LEVELS, TEMP_LEVELS} from "./consts";

export enum ProtectionClasses {
  NONE, // 'brak potrzeby',
  P1, // 'FFP1/P1',
  P1_P2, // 'FFP1/P1 lub FFP2/P2',
  P2, // 'FFP2/P2',
  P2_P3, // 'FFP2/P2 lub FFP3/P3 lub sprzęt z wymuszonym przepływem powietrza z filtrami klasy P2/P2',
  P3, // 'FFP3/P3',
  ABSORBING, // 'sprzęt pochłaniający',
  INSULATING, // 'sprzęt izolujący',
}

enum WorkplaceEnum {
  AGRICULTURE,
  SEWAGE,
  WASTE,
}

enum EnvironmentEnum {
  INDOOR,
  OUTDOOR,
}

enum OxygenEnum {
  BELOW_LEVEL,
  ABOVE_LEVEL,
}

enum BooleanEnum {
  TRUE,
  FALSE,
}

enum TempEnum {
  LOW,
  MEDIUM,
  HIGH,
}

enum HumidityEnum {
  LOW,
  MEDIUM,
  HIGH,
}

enum DustEnum {
  FIRST,
  SECOND,
  THIRD,
  FOURTH,
  FIFTH,
}

enum MicroRiskEnum {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
}

enum MicroNumEnum {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
  FIFTH = 5,
}

enum MetabolitesEnum {
  NONE,
  ENDOTOXIN,
  MOLD,
  OTHER,
}

enum HighLowEnum {
  LOW,
  HIGH,
}

export abstract class Field {
  abstract advance: boolean;

  getProtectionClass(value: number, formData: object): ProtectionClasses|null {
    return null;
  }

  getMcu(value: number, formData: object): number|null {
    return null;
  }
}

export abstract class SelectField extends Field {
  abstract options: object;
}

export abstract class CheckboxField extends Field {}

class WorkplaceField extends SelectField {
  advance = false;
  options = {
    [WorkplaceEnum.AGRICULTURE]: 'agriculture',
    [WorkplaceEnum.SEWAGE]: 'sewage',
    [WorkplaceEnum.WASTE]: 'waste',
  };
}

class EnvironmentField extends SelectField {
  advance = false;
  options = {
    [EnvironmentEnum.INDOOR]: `indoor`,
    [EnvironmentEnum.OUTDOOR]: 'outdoor',
  };

  getMcu(value: number, formData: object): number|null {
    switch (value) {
      case EnvironmentEnum.INDOOR:
        return 0.5;
      case EnvironmentEnum.OUTDOOR:
        return 1;
      default:
        return null;
    }
  }
}

class OxygenField extends SelectField {
  advance = false;
  options = {
    [OxygenEnum.BELOW_LEVEL]: `< ${Consts.OXYGEN_LEVEL}%`,
    [OxygenEnum.ABOVE_LEVEL]: `>= ${Consts.OXYGEN_LEVEL}%`,
  }

  getProtectionClass(value: number, formData: object): ProtectionClasses|null {
    switch (value) {
      case OxygenEnum.BELOW_LEVEL:
        return ProtectionClasses.INSULATING;
      case OxygenEnum.ABOVE_LEVEL:
        return ProtectionClasses.NONE;
      default:
        return null;
    }
  }
}

class GasSteamPresenceField extends SelectField {
  advance = false;
  options = {
    [BooleanEnum.TRUE]: 'yes',
    [BooleanEnum.FALSE]: 'no',
  };

  getProtectionClass(value: number, formData: object): ProtectionClasses | null {
    switch (value) {
      case BooleanEnum.TRUE:
        return ProtectionClasses.ABSORBING;
      case BooleanEnum.FALSE:
        return ProtectionClasses.NONE;
      default:
        return null;
    }
  }
}

class TempField extends SelectField {
  advance = false;
  options = {
    [TempEnum.LOW]: `<${TEMP_LEVELS.low}°C`,
    [TempEnum.MEDIUM]: `<${TEMP_LEVELS.low}°C, ${TEMP_LEVELS.high}°C)`,
    [TempEnum.HIGH]: `>=${TEMP_LEVELS.high}°C`,
  };

  getMcu(value: number, formData: object): number | null {
    switch (value) {
      case TempEnum.LOW:
        return 1;
      case TempEnum.MEDIUM:
        return 0.5;
      case TempEnum.HIGH:
        return 0.25;
      default:
        return null;
    }
  }
}

class HumidityField extends SelectField {
  advance = false;
  options = {
    [HumidityEnum.LOW]: `<${HUMIDITY_LEVELS.low}%`,
    [HumidityEnum.MEDIUM]: `<${HUMIDITY_LEVELS.low}%, ${HUMIDITY_LEVELS.high}%)`,
    [HumidityEnum.HIGH]: `>=${HUMIDITY_LEVELS.high}%`
  };

  getMcu(value: number, formData: object): number | null {
    switch (value) {
      case HumidityEnum.LOW:
        return 1;
      case HumidityEnum.MEDIUM:
        return 0.5;
      case HumidityEnum.HIGH:
        return 0.25;
      default:
        return null;
    }
  }
}

class DustField extends SelectField {
  advance = false;
  options = {
    [DustEnum.FIRST]: 'nds1',
    [DustEnum.SECOND]: 'nds2',
    [DustEnum.THIRD]: 'nds3',
    [DustEnum.FOURTH]: 'nds4',
    [DustEnum.FIFTH]: 'nds5',
  };

  getProtectionClass(value: number, formData: object): ProtectionClasses | null {
    switch (value) {
      case DustEnum.FIRST:
        return ProtectionClasses.NONE;
      case DustEnum.SECOND:
        return ProtectionClasses.P1;
      case DustEnum.THIRD:
        return ProtectionClasses.P2;
      case DustEnum.FOURTH:
        return ProtectionClasses.P3;
      case DustEnum.FIFTH:
        return ProtectionClasses.INSULATING;
      default:
        return null;
    }
  }

  getMcu(value: number, formData: object): number | null {
    switch (value) {
      case DustEnum.FIRST:
      case DustEnum.SECOND:
        return 1;
      case DustEnum.THIRD:
        return 0.75;
      case DustEnum.FOURTH:
        return 0.5;
      default:
        return null;
    }
  }
}

class BeardField extends CheckboxField {
  advance = false;
}

class IrregularFaceField extends CheckboxField  {
  advance = false;
}

class GlassesField extends CheckboxField {
  advance = false;
}

class MicroRiskGroupField extends SelectField {
  advance = true;
  options = {
    [MicroRiskEnum.FIRST]: 'microriskGroup1',
    [MicroRiskEnum.SECOND]: 'microriskGroup2',
    [MicroRiskEnum.THIRD]: 'microriskGroup3',
    [MicroRiskEnum.FOURTH]: 'microriskGroup4',
  };

  getProtectionClass(value: number, formData: object): ProtectionClasses | null {
    switch (value) {
      case MicroRiskEnum.FIRST:
        return ProtectionClasses.NONE;
      case MicroRiskEnum.SECOND:
        return ProtectionClasses.P1_P2;
      case MicroRiskEnum.THIRD:
        return ProtectionClasses.P2_P3;
      case MicroRiskEnum.FOURTH:
        return ProtectionClasses.INSULATING;
      default:
        return null;
    }
  }

  getMcu(value: number, formData: object): number | null {
    switch (value) {
      case MicroRiskEnum.FIRST:
        return 1;
      case MicroRiskEnum.SECOND:
        return 0.75;
      case MicroRiskEnum.THIRD:
        return 0.5;
      default:
        return null;
    }
  }
}

class MicroNumField extends SelectField {
  advance = true;
  options = {
    [MicroNumEnum.FIRST]: 'microNum1',
    [MicroNumEnum.SECOND]: 'microNum2',
    [MicroNumEnum.THIRD]: 'microNum3',
    [MicroNumEnum.FOURTH]: 'microNum4',
    [MicroNumEnum.FIFTH]: 'microNum5',
  };

  getProtectionClass(value: number, formData: object): ProtectionClasses | null {
    switch (value) {
      case MicroNumEnum.FIRST:
        return ProtectionClasses.NONE;
      case MicroNumEnum.SECOND:
        return ProtectionClasses.P1;
      case MicroNumEnum.THIRD:
        return ProtectionClasses.P2;
      case MicroNumEnum.FOURTH:
        return ProtectionClasses.P3;
      case MicroNumEnum.FIFTH:
        return ProtectionClasses.INSULATING;
      default:
        return null;
    }
  }

  getMcu(value: number, formData: object): number | null {
    switch (value) {
      case MicroNumEnum.SECOND:
        return 1;
      case MicroNumEnum.THIRD:
        return 0.75;
      case MicroNumEnum.FOURTH:
        return 0.5;
      default:
        return null;
    }
  }
}

class MetabolitesAirField extends SelectField {
  advance = true;

  options = {
    [MetabolitesEnum.NONE]: 'none',
    [MetabolitesEnum.ENDOTOXIN]: 'endotoxin',
    [MetabolitesEnum.MOLD]: 'mold',
    [MetabolitesEnum.OTHER]: 'other',
  }

  getProtectionClass(value: number, formData: object): ProtectionClasses | null {
    switch (value) {
      case MetabolitesEnum.NONE:
        return ProtectionClasses.NONE;
      case MetabolitesEnum.ENDOTOXIN:
      case MetabolitesEnum.MOLD:
      case MetabolitesEnum.OTHER:
        return ProtectionClasses.P2;
      default:
        return null;
    }
  }

  getMcu(value: number, formData: object): number | null {
    switch (value) {
      case MetabolitesEnum.NONE:
        return 1;
      case MetabolitesEnum.ENDOTOXIN:
      case MetabolitesEnum.MOLD:
      case MetabolitesEnum.OTHER:
        return 0.75;
      default:
        return null;
    }
  }
}

class MetabolitesDustField extends SelectField {
  advance = true;

  options = {
    [MetabolitesEnum.NONE]: 'none',
    [MetabolitesEnum.ENDOTOXIN]: 'endotoxin',
    [MetabolitesEnum.MOLD]: 'mold',
    [MetabolitesEnum.OTHER]: 'other',
  }

  getProtectionClass(value: number, formData: object): ProtectionClasses | null {
    switch (value) {
      case MetabolitesEnum.NONE:
        return ProtectionClasses.NONE;
      case MetabolitesEnum.ENDOTOXIN:
      case MetabolitesEnum.MOLD:
      case MetabolitesEnum.OTHER:
        return ProtectionClasses.P1_P2;
      default:
        return null;
    }
  }
}

class MetabolitesAirNumField extends SelectField {
  advance = true;

  options = {
    [HighLowEnum.LOW]: 'metabolitesAirNumLow',
    [HighLowEnum.HIGH]: 'metabolitesAirNumHigh',
  }

  getProtectionClass(value: number, formData: object): ProtectionClasses | null {
    switch (value) {
      case HighLowEnum.LOW:
        return ProtectionClasses.P1;
      case HighLowEnum.HIGH:
        return ProtectionClasses.P2;
      default:
        return null;
    }
  }
}

class MetabolitesDustNumField extends SelectField {
  advance = true;

  options = {
    [HighLowEnum.LOW]: 'metabolitesDustNumLow',
    [HighLowEnum.HIGH]: 'metabolitesDustNumHigh',
  }

  getProtectionClass(value: number, formData: object): ProtectionClasses | null {
    switch (value) {
      case HighLowEnum.LOW:
        return ProtectionClasses.P1;
      case HighLowEnum.HIGH:
        return ProtectionClasses.P2;
      default:
        return null;
    }
  }
}

class CytotoxicityDustField extends SelectField {
  advance = true;

  options = {
    [HighLowEnum.LOW]: 'cytotoxicityLow',
    [HighLowEnum.HIGH]: 'cytotoxicityHigh',
  }

  getProtectionClass(value: number, formData: object): ProtectionClasses | null {
    switch (value) {
      case HighLowEnum.LOW:
        return ProtectionClasses.NONE;
      case HighLowEnum.HIGH:
        return ProtectionClasses.P3;
      default:
        return null;
    }
  }
}

class BiodiversityAirField extends SelectField {
  advance = true;

  options = {
    [HighLowEnum.LOW]: 'biodiversityLow',
    [HighLowEnum.HIGH]: 'biodiversityHigh',
  }

  getProtectionClass(value: number, formData: object): ProtectionClasses | null {
    switch (value) {
      case HighLowEnum.LOW:
        return ProtectionClasses.P2;
      case HighLowEnum.HIGH:
        return ProtectionClasses.NONE;
      default:
        return null;
    }
  }
}

class BiodiversityDustField extends SelectField {
  advance = true;

  options = {
    [HighLowEnum.LOW]: 'biodiversityLow',
    [HighLowEnum.HIGH]: 'biodiversityHigh',
  }

  getProtectionClass(value: number, formData: object): ProtectionClasses | null {
    switch (value) {
      case HighLowEnum.LOW:
        return ProtectionClasses.P2;
      case HighLowEnum.HIGH:
        return ProtectionClasses.NONE;
      default:
        return null;
    }
  }
}

const EquipmentForm: { [key: string]: Field } = {
  workplace: new WorkplaceField(),
  environment: new EnvironmentField(),
  oxygen: new OxygenField(),
  gas_steam_presence: new GasSteamPresenceField(),
  temp: new TempField(),
  humidity: new HumidityField(),
  dust: new DustField(),
  beard: new BeardField(),
  irregular_face: new IrregularFaceField(),
  glasses: new GlassesField(),
  micro_risk_group: new MicroRiskGroupField(),
  micro_risk_num: new MicroNumField(),
  metabolites_air: new MetabolitesAirField(),
  metabolites_dust: new MetabolitesDustField(),
  metabolites_air_num: new MetabolitesAirNumField(),
  metabolites_dust_num: new MetabolitesDustNumField(),
  cytotoxicity_dust: new CytotoxicityDustField(),
  biodiversity_air: new BiodiversityAirField(),
  biodiversity_dust: new BiodiversityDustField(),
}

export default EquipmentForm;
