import {ImageURISource} from "react-native";
import React from "react";
import P from "./P";
import {ProtectionClasses} from "../forms/EquipmentForm";

class P3 extends P {
  readonly name: string = 'półmaska z filtrami klasy P3';
  readonly image: ImageURISource = require('../assets/equipment/P.jpg');
  readonly protectionClass: ProtectionClasses = ProtectionClasses.P3;

  readonly code: string = 'P3';
  readonly index: string = 'P3';
  readonly oil: string = '0,05';
  readonly aerosol: string = '0,05';
}

export default new P3();
