import {IEquipmentType} from "./index";
import {ImageURISource} from "react-native";
import {Box, Text, VStack} from "native-base";
import React from "react";
import {TFunction} from "i18next";
import {ProtectionClasses} from "../forms/EquipmentForm";

export default abstract class THP implements IEquipmentType {
  abstract readonly name: string;
  abstract readonly image: ImageURISource;
  abstract readonly protectionClass: ProtectionClasses;

  abstract readonly code: string;
  abstract readonly index: string;

  readonly videos = [
    {uri: 'https://ciop-stats.devmouse.pl/movie/TH.mov'},
  ];

  description = (t: TFunction): React.ReactNode => <VStack space={2}>
    <Text>{t('thp_p1')}</Text>
    <Text>{t('thp_p2')}</Text>
    <Text>{t('thp_p3')}</Text>
    <Box>
      <Text>{t('thp_p4')}</Text>
      <Text>{t('thp_p5')}</Text>
      <Text>{t('thp_p6')}</Text>
    </Box>
  </VStack>;

  warnings = (t: TFunction): React.ReactNode => <Box>
    <Text>{t('thp_warn1')}</Text>
    <Text>{t('thp_warn2')}</Text>
    <Text>{t('thp_warn3')}</Text>

    <Text marginTop={2}>{t('thp_warn4')}</Text>
  </Box>;

  labeling = (t: TFunction): React.ReactNode => <Box>
    <Text>{t('thp_label1')}</Text>

    <Text marginTop={2}>{t('thp_label2')}</Text>
    <Text>{t('thp_label3')}</Text>
    <Text>{t('thp_label4')}</Text>
    <Text>{t('thp_label5')}</Text>
    <Text>{t('thp_label6')}</Text>
    <Text>{t('thp_label7')}</Text>
    <Text>{t('thp_label8')}</Text>
    <Text>{t('thp_label9')}</Text>

    <Text marginTop={2}>{t('thp_label10')}</Text>
    <Text>{t('thp_label11')}</Text>
    <Text>{t('thp_label12')}</Text>
    <Text>{t('thp_label13')}</Text>
    <Text>{t('thp_label14')}</Text>
    <Text>{t('thp_label15')}</Text>
    <Text>{t('thp_label16')}</Text>

    <Text marginTop={2}>{t('thp_label17')}</Text>
  </Box>;
}
