import {ImageBackground, Platform, useWindowDimensions} from "react-native";
import React from "react";
import {Box, HStack, ScrollView, Text, useMediaQuery, View, VStack} from "native-base";
import Header from "./web/Header";
import Footer from "./web/Footer";
import QuoteRotator from "./QuoteRotator";

export default function ContentWrapper(params : {children: React.ReactNode}) {
  const { height } = useWindowDimensions();
  const [isSmallScreen] = useMediaQuery({
    minWidth: 0,
    maxWidth: 800
  });

  if (Platform.OS === 'web') {
    return <ScrollView maxHeight={height} backgroundColor='#FFF' flexGrow={1}>
      <View flexGrow={1} minHeight={height}>
        <Header />

        <Box flexGrow={1}>
          <HStack paddingLeft={5} flexGrow={1}>
            <Box flex={1} paddingRight={5} paddingY={4}>
              {params.children}
            </Box>

            {! isSmallScreen && <ImageBackground style={{flex: 1}} source={require('../assets/bg.jpg')} resizeMode='cover'>
              <View
                flex={1}
                position='absolute'
                top={0} right={0} bottom={0} left={0}
                bg='secondary.500'
                opacity={0.9}
              />

              <QuoteRotator />
            </ImageBackground>}
          </HStack>
        </Box>

        <Box marginTop='auto'>
          <Footer />
        </Box>
      </View>
    </ScrollView>;
  }

  return <ScrollView flex={1} backgroundColor='#FFF' paddingX={5} maxHeight={height}>
    {params.children}
  </ScrollView>
}
