import HeaderBackImage from "../components/HeaderBackImage";
import * as React from "react";
import {StackNavigationOptions} from "@react-navigation/stack";
import {Platform} from "react-native";
import {NativeStackNavigationOptions} from "react-native-screens/native-stack";

export const NativeScreenOptions: () => NativeStackNavigationOptions = () => {
  return {
    headerBackTitleVisible: false,
    headerBackImage: () => <HeaderBackImage/>,
    headerTitleStyle: {
      fontSize: 22,
      fontWeight: '500',
    },
    headerShown: Platform.OS !== 'web',
  }
}

export const ScreenOptions: () => StackNavigationOptions = () => {
  return {
    headerBackTitleVisible: false,
    headerBackImage: () => <HeaderBackImage/>,
    headerTitleStyle: {
      fontSize: 22,
      fontWeight: '500',
    },
    headerStyle: {
      height: 100,
    },
    headerShown: Platform.OS !== 'web',
  }
}
