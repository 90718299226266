import React, {useCallback, useRef} from "react";
import {Box, Heading, Hidden, VStack} from "native-base";
import {Accordion} from "./components/Accordion";
import {useTranslation} from "react-i18next";
import GradientButton from "../../components/GradientButton";
import {Video} from "expo-av";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {STORAGE} from "../../consts";
import ContentWrapper from "../../components/ContentWrapper";
import {useTimer} from "../../providers/TimerProvider";
import {States} from "../../enums/StatesEnum";
import {useEquipment} from "../../providers/EquipmentProvider";
import {BackHandler, View} from "react-native";
import {AVPlaybackStatus} from "expo-av/build/AV";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import {NavigationProp} from "@react-navigation/core/src/types";
import {BottomTabParamList} from "../../Types";

export default function EquipmentDetailScreen({navigation}) {
  const { navigate } = useNavigation<NavigationProp<BottomTabParamList>>();

  const {t} = useTranslation('EquipmentDetailScreen');
  const {setTimer} = useTimer();
  const {equipment} = useEquipment();

  const ref = useRef(null);

  const handleOnPress = async () => {
    await AsyncStorage.removeItem(STORAGE.TIMER);

    let mcu = await AsyncStorage.getItem(STORAGE.MCU);
    let timeleft = (mcu ? parseInt(mcu) : 0);

    setTimer({
      state: States.INITIAL,
      expiration: new Date(Date.now() + timeleft),
      timeLeft: timeleft,
    });
  };

  const onStatusUpdate = (status: AVPlaybackStatus) => {
    if (status.didJustFinish) {
      ref?.current?.dismissFullscreenPlayer();
    }
  }

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        navigate('FormStack', {
          screen: 'Results',
        });

        return true;
      };

      BackHandler.addEventListener('hardwareBackPress', onBackPress);
      return () =>
        BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    }, [])
  );

  React.useEffect(() => {
    return navigation.addListener('blur', () => {
      ref.current?.stopAsync();
    });
  }, [navigation]);


  if (!equipment) {
    return <View/>;
  }

  return (
    <ContentWrapper>
      <Accordion>
        <Accordion.Item>
          <Accordion.Summary>
            <Heading fontSize={20}>{t('description')}</Heading>
          </Accordion.Summary>

          <Accordion.Details>
            {equipment.description(t)}
          </Accordion.Details>
        </Accordion.Item>

        <Accordion.Item>
          <Accordion.Summary>
            <Heading fontSize={20}>{t('howToUse')}</Heading>
          </Accordion.Summary>

          <Accordion.Details>
            <Box flexDirection='row'>
              {equipment.videos.map((video, index) =>
                <Box style={{width: '50%'}} key={index}>
                  <Video
                    key={index}
                    style={{
                      marginTop: 2,
                      height: 200,
                    }}
                    source={video}
                    ref={ref}
                    useNativeControls
                    resizeMode="contain"
                    onPlaybackStatusUpdate={onStatusUpdate}
                  />
                </Box>
              )}
            </Box>
          </Accordion.Details>
        </Accordion.Item>

        <Accordion.Item>
          <Accordion.Summary>
            <Heading fontSize={20}>{t('warningsAndLabeling')}</Heading>
          </Accordion.Summary>

          <Accordion.Details>
            <VStack space={2}>
              <Heading size='sm'>{t('warnings')}:</Heading>
              {equipment.warnings(t)}

              <Heading size='sm'>{t('labeling')}:</Heading>
              {equipment.labeling(t)}
            </VStack>
          </Accordion.Details>
        </Accordion.Item>
      </Accordion>

      <Hidden platform={['web']}>
        <Box marginTop='auto'>
          {/* @ts-ignore */}
          <GradientButton _gradientStack={{flex: 1, marginTop: 5, marginBottom: 10}} size='lg' onPress={handleOnPress}>
            {t('runTimer')}
          </GradientButton>
        </Box>
      </Hidden>
    </ContentWrapper>
  );
}
