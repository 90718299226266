import {Box, PresenceTransition, Text} from "native-base";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export default function QuoteRotator() {
  const {t} = useTranslation('QuoteRotator');

  const [isOpen, setIsOpen] = useState(true);
  const [text, setText] = useState('');

  const quotes = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
  ];

  const randomQuote = () => {
    return quotes[Math.floor(Math.random()*quotes.length)];
  }

  useEffect(() => {
    setText(randomQuote());

    const interval = setInterval(() => {
      setIsOpen(false);

      setTimeout(() => {
        setText(randomQuote());
        setIsOpen(true);
      }, 750);
    }, 8000);
    return () => clearInterval(interval);
  }, []);


  return <Box flex={1} alignItems='center' justifyContent='center' paddingX={20}>
    <PresenceTransition
      visible={isOpen}
      initial={{
        opacity: 0
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 500
        }
      }}
    >
      <Text color='white' fontSize='8xl' lineHeight='24' textAlign='center' fontFamily='serif'>“</Text>
      <Text color='white' fontSize='2xl' textAlign='center'>{t(text)}</Text>
    </PresenceTransition>
  </Box>
}
