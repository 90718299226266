export function renderTime(time: number): string {
  if (time <= 0) {
    return '00:00';
  }

  let hours = Math.floor(time / 3600);
  let minutes = Math.floor((time - hours * 3600) / 60);
  let seconds = time - hours * 3600 - minutes * 60;

  if (hours > 0) {
    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}
