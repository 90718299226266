import {createStackNavigator} from "@react-navigation/stack";
import {HistoryParamList} from "../../Types";
import * as React from "react";
import HistoryScreen from "./HistoryScreen";
import {ScreenOptions, NativeScreenOptions} from "../index";
import {useTranslation} from "react-i18next";
import {Platform} from "react-native";
// import {createNativeStackNavigator} from "react-native-screens/native-stack";

// @ts-ignore
let HistoryStack;
let options;

// if (Platform.OS === 'web') {
  HistoryStack = createStackNavigator<HistoryParamList>();
  options = ScreenOptions;
// } else {
  // import {createNativeStackNavigator} from "react-native-screens/native-stack";
  //
  // HistoryStack = createNativeStackNavigator<HistoryParamList>();
  // options = NativeScreenOptions;
// }

export default function HistoryNavigator() {
  const { t } = useTranslation();

  return (
    // @ts-ignore
    <HistoryStack.Navigator
      screenOptions={ScreenOptions}
    >
      {/*@ts-ignore*/}
      <HistoryStack.Screen
        name="History"
        component={HistoryScreen}
        options={{
          title: t('HistoryScreen:title'),
          headerTitle: t('HistoryScreen:title'),
        }}
      />
      {/*@ts-ignore*/}
    </HistoryStack.Navigator>
  );
}
