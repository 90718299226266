import {ImageURISource} from "react-native";
import React from "react";
import THP from "./THP";
import {ProtectionClasses} from "../forms/EquipmentForm";
import {TFunction} from "i18next";
import {Box, Text, VStack} from "native-base";

class THP2 extends THP {
  readonly name: string = 'sprzęt z wymuszonym przepływem klasy THP2';
  readonly image: ImageURISource = require('../assets/equipment/TH.jpg');
  readonly protectionClass: ProtectionClasses = ProtectionClasses.P2;

  readonly code: string = 'THP2';
  readonly index: string = 'THP2';

  description = (t: TFunction): React.ReactNode => <VStack space={2}>
    <Text>{t('thp_p1')}</Text>
    <Text>{t('thp_p2')}</Text>
    <Text>{t('thp_p3')}</Text>
    <Box>
      <Text>{t('thp_p5')}</Text>
    </Box>
  </VStack>;
}

export default new THP2();
