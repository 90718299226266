import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Localization from "expo-localization";
import {STORAGE} from "../consts";

const LanguageDetectorPlugin = {
  type: "languageDetector",
  async: true,
  init: () => {},
  detect: async function (callback: (lang: string) => void) {
    try {
      //get stored language from Async storage
      await AsyncStorage.getItem(STORAGE.LANGUAGE).then((language) => {
        if (language === 'pl' || language === 'en') {
          return callback(language);
        }

        return callback('pl');
      });
    } catch (error) {
      console.log("Error reading language", error);
    }
  },
  cacheUserLanguage: async function (language: string) {
    try {
      //save a user's language choice in Async storage
      await AsyncStorage.setItem(STORAGE.LANGUAGE, language);
    } catch (error) {}
  },
};

export {LanguageDetectorPlugin};
